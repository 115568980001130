import { useTranslation } from 'react-i18next';
import Tickets from '../../Tickets/Tickets';
import styles from './HotelTickets.module.scss';
import '../../../../translations/i18n';
import VoucherDownload from '../VoucherDownload/VoucherDownload';

function HotelTickets(props) {
  const { t } = useTranslation('common');
  const { tickets, orderId } = props;

  const ticketsToDisplay = Object.entries(tickets.tickets).map(
    ([key, value]) => (
      <>
        <ul className={`${styles.ticketsList} ${styles.hotelTicketsList}`}>
          <Tickets
            key={value.voucher.voucherTd}
            id={value.voucher.voucherTd}
            title={value.title}
            date={
              value.dateInfo
                ? value.dateInfo
                : Object.values(value.orderTickets[0])[0].date
            }
            img={value.img}
            price={value.orderTickets.reduce(
              (accumulator, orderTic) => accumulator
                + Object.values(orderTic)[0].qty
                  * parseFloat(Object.values(orderTic)[0].price),
              0,
            )}
            currency={tickets.vouchers.currency}
            tickets={value.orderTickets.map((orderTic) => {
              const ticketDetails = Object.values(orderTic)[0];
              const ticketDesc = `${ticketDetails.qty} x ${ticketDetails.type} ${ticketDetails.description}`;
              return ticketDesc;
            })}
            whereShown="hotelTicketsPage"
            destination={Object.values(value.orderTickets[0])[0].destination}
            typeTicket={Object.values(value.orderTickets[0])[0].type_ticket}
          />
        </ul>
        <VoucherDownload
          vouchers={value.voucher}
          bookingCanceled={tickets.bookingCanceled}
          orderId={orderId}
          productName={value.title}
        />
      </>
    ),
  );

  return (
    <>
      <h2 className={styles.title}>{t('payment.tickets')}</h2>
      {ticketsToDisplay.map((ticketToDisplay) => ticketToDisplay)}
    </>
  );
}

export default HotelTickets;
