import { useEffect, useState } from 'react';
import { getLang, getEnv } from '../../../utils/OrderUtils';

function PageView({ userEmail, orderId }) {
  const userId = document.cookie
    .split('; ')
    .find((row) => row.startsWith('user='))
    ?.split('=')[1];

  // Handle page data.
  useEffect(() => {
    if (!window.dataLayer) {
      window.dataLayer = [];
    }
    window.dataLayer[0] = window.dataLayer[0] || {};
    const page = {
      node_type: 'customer_accounts',
      node_title: 'Customer Accounts',
      user_type: userId,
      user_email: userEmail,
      order_number: orderId,
      environment: getEnv(),
      domain: getLang(),
    };

    window.dataLayer[0].page = page;
  }, [userId, userEmail, orderId]);
}

export default PageView;
