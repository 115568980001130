import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as utils from '../../../../utils/OrderUtils';
import styles from './HotelDetails.module.scss';
import '../../../../translations/i18n';

function HotelDetails(props) {
  const {
    tabID, details, transport, isParisPackage,
  } = props;
  const [roomsAdded, setRoomsAdded] = useState(false);
  useEffect(() => {
    if (Object.keys(details.rooms).length > 0) {
      setRoomsAdded(true);
    }
  }, [details]);

  const { t } = useTranslation('common');
  return (
    <>
      <h2 className={styles.title}>{t('tickets.hotel_details')}</h2>
      <div className={styles.hotel}>
        <div className={styles.image}>
          <img alt="" src={details.img} />
        </div>
        <div className={styles.info}>
          <div className={styles.infoHeader}>
            <div>
              <h3 className={styles.hotelName}>{details.title}</h3>
              <h4 className={styles.hotelSubtitle}>
                {details.rooms[0][0].orderTicketId.title}
              </h4>
            </div>
            <h4 className={styles.bookingStatus}>{details.bookingStatus}</h4>
          </div>
          <div className={styles.details}>
            <div className={styles.detailsChunk}>
              <ul className={styles.checkInOut}>
                <li className={styles.checkInOutItem}>
                  <em>
                    {t('tickets.checkin')}
                    :
                    {' '}
                  </em>
                  <strong>{utils.formatDate(details.checkin)}</strong>
                </li>
                <li className={styles.checkInOutItem}>
                  <em>
                    {t('tickets.checkout')}
                    :
                    {' '}
                  </em>
                  <strong>{utils.formatDate(details.checkout)}</strong>
                </li>
                <li className={styles.checkInOutItem}>
                  <em>
                    {t('tickets.hotels_confirm')}
                    :
                    {' '}
                  </em>
                  <span className={styles.supplierConfirmationNumber}>
                    {details?.supplierConfirmationNumber?.length > 0
                      ? details.supplierConfirmationNumber
                      : 'Pending'}
                  </span>
                </li>
              </ul>
            </div>
            <div className={styles.priceIncludes}>
              {details.ticketDaysDuration > 0 ? (
                <p className={styles.includesHeading}>
                  {t('tickets.price_includes')}
                  {transport.tickets.inbound && t('tickets.eurostar1')}
                  {roomsAdded && t('tickets.accommodation')}
                  {details.tickets && isParisPackage && t('tickets.tickets')}
                </p>
              ) : null}

              <ul className={styles.includes}>
                {transport.tickets.inbound && (
                  <li className={`${styles.includesItem} ${styles.eurostar}`}>
                    <svg>
                      <use xlinkHref="#eurostar" />
                    </svg>
                    <strong>{t('tickets.eurostar')}</strong>
                  </li>
                )}
                {roomsAdded && details.duration > 0 && (
                  <li className={`${styles.includesItem} ${styles.nights}`}>
                    <svg>
                      <use xlinkHref="#bed" />
                    </svg>
                    <strong>
                      {details.duration}
                      {' '}
                      {t('tickets.nights')}
                    </strong>
                  </li>
                )}
                {details.ticketDaysDuration > 0 && isParisPackage && (
                  <li className={`${styles.includesItem} ${styles.daysParks}`}>
                    <svg>
                      <use xlinkHref="#tickets" />
                    </svg>
                    <strong>
                      <span>
                        {details.ticketDaysDuration}
                        {' '}
                        {t('tickets.days')}
                        {' '}
                      </span>
                      <span>
                        2
                        {t('tickets.parks')}
                        {' '}

                      </span>
                    </strong>
                  </li>
                )}
              </ul>
              <ul className={styles.address}>
                {details.hotelAddress && details.hotelAddress.length > 0 && (
                  <>
                    {details.hotelAddress.name.length > 0 && (
                      <li className={styles.addressItem}>
                        {details.hotelAddress.name}
                      </li>
                    )}
                    {details.hotelAddress.street1.length > 0 && (
                      <li className={styles.addressItem}>
                        {details.hotelAddress.street1}
                      </li>
                    )}
                    {details.hotelAddress.city.length > 0 && (
                      <li className={styles.addressItem}>
                        {details.hotelAddress.city}
                      </li>
                    )}
                    {details.hotelAddress.region.length > 0 && (
                      <li className={styles.addressItem}>
                        {details.hotelAddress.region}
                      </li>
                    )}
                    {details.hotelAddress.postcode.length > 0 && (
                      <li className={styles.addressItem}>
                        {details.hotelAddress.postcode}
                      </li>
                    )}
                    {details.hotelAddress.country.length > 0 && (
                      <li className={styles.addressItem}>
                        {details.hotelAddress.country}
                      </li>
                    )}
                  </>
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default HotelDetails;
