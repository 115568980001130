import {
  useState, useEffect, useRef, useContext,
} from 'react';
import { Frames, CardFrame } from 'frames-react';
import { useTranslation } from 'react-i18next';
import {
  CheckoutDotComAPIPayment,
  CheckoutDotComAPISettings,
  CheckoutDotComAPIPaymentWithPreviousCard,
} from '../../../../api/CheckoutDotComAPI';
import API from '../../../../api/phx';
import SessionContext from '../../../../context/SessionContext';
import PaymentContext from '../../../../context/PaymentContext';
import styles from './BankPayment.module.scss';
import '../../../../translations/i18n';
import * as utils from '../../../../utils/OrderUtils';
import { getAppConfig } from '../../../../config/config';
import Payments from '../../../Analytics/Payments/Payments';
import Checkboxes from '../Checkboxes/Checkboxes';
import updateOrderDetails from '../../../../api/UpdateOrderDetails';

function BankPayment(props) {
  const { t } = useTranslation('common');
  const {
    order,
    payAmount,
    cardholderName,
    isBillingDetailsValid,
    isShowStoreCard,
    isValidAmount,
  } = props;

  const config = getAppConfig();

  const [checkoutDotComPublicKey, setCheckoutDotComPublicKey] = useState('');

  const [cardValid, setCardValid] = useState(false);
  const [isPaymentValid, setIsPaymentValid] = useState(false);

  const [redirectUrl, setRedirectUrl] = useState('');
  const [prevCardRedirectUrl, setPrevCardRedirectUrl] = useState('');
  const [previousCards, setPreviousCards] = useState('');
  const [selectedPreviousCard, setSelectedPreviousCard] = useState();

  const [formSubmitting, setFormSubmitting] = useState(false);
  const [previousCardFormSubmitting, setPreviousCardFormSubmitting] = useState(false);

  const [cvvStatus, setCvvStatus] = useState(false);
  const [showPayNowBtn, setShowPayNowBtn] = useState(true);
  const [showPayBtn, setShowPayBtn] = useState(true);
  const [show3DSIframe, setShow3DSIframe] = useState(false);

  const [saveCardChecked, setSaveCardChecked] = useState(false);
  const [confirmQuoteChecked, setConfirmQuoteChecked] = useState(false);
  const [confirmTermsChecked, setConfirmTermsChecked] = useState(order && order.agree_terms === '1');
  const [receiveOffersChecked, setReceiveOffersChecked] = useState(order && order.agree_marketing === '1');

  const handleCvv = () => {
    setCvvStatus(!cvvStatus);
  };

  const handleSaveCardChange = (e) => {
    setSaveCardChecked(e.target.checked);
  };

  const handleConfirmQuoteChange = (checked) => {
    setConfirmQuoteChecked(checked);
  };

  const handleConfirmTermsChange = (checked) => {
    setConfirmTermsChecked(checked);
  };

  const handleReceiveOffersChange = (checked) => {
    setReceiveOffersChecked(checked);
  };

  const paymentCtx = useContext(PaymentContext);
  const { paymentData } = paymentCtx;
  const resetForm = () => {
    setFormSubmitting(false);
    setPreviousCardFormSubmitting(false);
    setRedirectUrl('');
    setPrevCardRedirectUrl('');
    setCardValid(false);
  };

  useEffect(() => {
    CheckoutDotComAPISettings().then((result) => {
      if (result && result.public_key) {
        setCheckoutDotComPublicKey(result.public_key);
      }
    });
  }, [checkoutDotComPublicKey]);

  const sessionCtx = useContext(SessionContext);
  const { session } = sessionCtx;
  const sessionId = session.sid;

  const checkstoredCards = () => {
    let isStoredCards = false;
    order?.order_payments.forEach((payments) => {
      if (payments.storecard === 'Y') {
        isStoredCards = true;
      }
    });
    return isStoredCards;
  };

  useEffect(() => {
    async function fetchData() {
      if (order && order.order_payments.length > 0) {
        const requestUrl = utils.appendGeoParam(
          `/payments/checkoutdotcom/card?action=list&account=1&order_id=${order.id}`,
          config.brand,
        );
        try {
          const result = await API.get(requestUrl);
          setPreviousCards(result.data);
        } catch (err) {
          console.error('Error:', err);
        }
      }
    }

    if (checkstoredCards() === true) {
      fetchData();
    }
  }, [order && order.id]);

  const stylesCardFrame = config.brand === 'UO' ? styles.cardFrameUO : styles.cardFrame;
  const frames = checkoutDotComPublicKey ? (
    <div className={`${styles.frames} checkoutdotcom-frames gb-mask`}>
      <Frames
        config={{
          debug: false,
          publicKey: checkoutDotComPublicKey,
          localization: {
            cardNumberPlaceholder: t('payment.card_number'),
            expiryMonthPlaceholder: 'MM',
            expiryYearPlaceholder: 'YY',
            cvvPlaceholder: t('payment.cvv'),
          },
          style: {
            base: {
              fontSize: '14px',
            },
          },
          cardholder: {
            name: cardholderName,
            phone: order.billing_address_phone,
            billingAddress: {
              addressLine1: order.billing_address_street1,
              addressLine2: order.billing_address_street2,
              zip: order.billing_address_postal_code,
              city: order.billing_address_city,
              state: order.billing_address_region,
            },
          },
        }}
        cardValidationChanged={(e) => setCardValid(e.isValid)}
        cardTokenized={(e) => {}}
        cardTokenizationFailed={(e) => {
          console.error('cardTokenizationFailed', e);
          setFormSubmitting(false);
          setPreviousCardFormSubmitting(false);
        }}
      >
        <CardFrame className={stylesCardFrame} />
      </Frames>
    </div>
  ) : null;

  const iframe3DS = redirectUrl ? (
    <iframe title="checkout" className={styles.iframe3DS} src={redirectUrl} />
  ) : null;

  const iframe3DSPrevCard = prevCardRedirectUrl ? (
    <iframe
      title="checkoutPrevCard"
      className={styles.iframe3DS}
      src={prevCardRedirectUrl}
    />
  ) : null;

  const formSubmit = (e) => {
    updateOrderDetails(order.id, order.owner, confirmQuoteChecked, receiveOffersChecked, confirmTermsChecked)
      .catch((error) => {
        console.error('Error updating order details:', error);
      });
    e.preventDefault();
    setFormSubmitting(true);
    setShowPayBtn(false);
    Frames.submitCard()
      .then((data) => {
        CheckoutDotComAPIPayment(data.token, 'card', payAmount, order.currency, order.email, saveCardChecked, order.id)
          .then((redirectUrls) => {
            setRedirectUrl(redirectUrls);
            setShow3DSIframe(true);
          });
      })
      .catch((error) => {
        // handle error
      });
  };

  // Payment failure handler.
  const failedPaymentHandler = () => {
    paymentData('payment-failed', payAmount);
    setFormSubmitting(false);
    setPreviousCardFormSubmitting(false);
    setShow3DSIframe(false);
    Frames.enableSubmitForm();
    resetForm();
  };

  const amountLeftToPay = String(
    order && Number(order.balance) - Number(payAmount),
  );
  // Payment success handler.
  const successPaymentHandler = () => {
    Payments(order, payAmount, 'card');
    paymentData('payment-success', amountLeftToPay);
    setFormSubmitting(false);
    setPreviousCardFormSubmitting(false);
    setShow3DSIframe(false);
    Frames.enableSubmitForm();
    resetForm();
  };

  window.top.checkoutDotComFailure = failedPaymentHandler;

  window.top.checkoutDotComSuccess = successPaymentHandler;

  const formSubmitPreviousCard = async (e) => {
    e.preventDefault();
    setPreviousCardFormSubmitting(true);
    const result = await CheckoutDotComAPIPaymentWithPreviousCard(
      selectedPreviousCard,
      payAmount,
      order.currency,
      order.email,
      order.id,
    );
    if (result) {
      setPrevCardRedirectUrl(result);
      setShowPayNowBtn(true);
    } else {
      paymentData('payment-failed', payAmount);
    }
  };

  const getPreviousCards = () => previousCards.map((previousCard) => {
    const expiryYear = previousCard.expiry_year
      .toString()
      .split('')
      .splice(2)
      .join('');
    let value = '';
    if (previousCard.expiry_month.toString().length === 1) {
      value = `${previousCard.scheme} ending in ${previousCard.last4} expires at ${0}${previousCard.expiry_month}/${expiryYear}`;
    } else {
      value = `${previousCard.scheme} ending in ${previousCard.last4} expires at ${previousCard.expiry_month}/${expiryYear}`;
    }
    return (
      <option value={previousCard.card_id} key={previousCard.card_id}>
        {value}
      </option>
    );
  });

  const handlePreviousCardChange = (e) => {
    setSelectedPreviousCard(e.target.value);
  };

  useEffect(() => {
    if (!cardValid || !payAmount || payAmount <= 0 || !isBillingDetailsValid) {
      setIsPaymentValid(false);
    } else {
      setIsPaymentValid(true);
    }
    if (cardValid && isValidAmount) {
      setShowPayBtn(true);
    } else {
      setShowPayBtn(false);
    }
  }, [showPayBtn, cardValid, payAmount, isBillingDetailsValid, isValidAmount]);

  return (
    <div className={styles.card}>
      <div className={styles.previousCards}>
        {previousCards ? (
          <>
            <h3 className={styles.cardHeading}>{t('payment.pay_used_card')}</h3>
            <select
              className={`${styles.previousSelect} gb-mask`}
              onChange={handlePreviousCardChange}
            >
              <option value="">{t('payment.previous_card_select')}</option>
              {getPreviousCards()}
            </select>
            {iframe3DSPrevCard}
            {!iframe3DSPrevCard && (
              <button
                className={`${previousCardFormSubmitting && 'loading'} ${styles.previousSubmit}`}
                onClick={formSubmitPreviousCard}
                type="submit"
                disabled={
                  !showPayNowBtn
                  || !payAmount
                  || !selectedPreviousCard
                  || formSubmitting
                }
              >
                {t('payment.pay_now')}
              </button>
            )}
          </>
        ) : null}
      </div>
      { showPayNowBtn && (
      <>
        <h3 className={styles.cardHeading}>{t('payment.pay_new_card')}</h3>
        { show3DSIframe ? iframe3DS : frames }
        {!show3DSIframe
        && (
        <>
          <button className={styles.cvvLink} onClick={handleCvv} type="submit">{t('payment.cvv_title')}</button>
          <div className={styles.cvvInfo}>
            {cvvStatus && (
            <>
              <p>{t('payment.cvv_desc')}</p>
              <h4>{t('payment.cvv_subtitle')}</h4>
              <p className={styles.cvvText}>
                <img src="/themes/custom/atd/images/cvv.jpg" alt="" />
                {t('payment.cvv_card_desc')}
              </p>
            </>
            )}
          </div>
          { (isShowStoreCard) ? (
            <div>
              <input value="test" type="checkbox" onChange={handleSaveCardChange} />
              {t('payment.storecard')}
            </div>
          ) : null }
          {['quote', 'in_checkout', 'payment_failed'].includes(order && order.status) && (
          <Checkboxes
            order={order}
            onConfirmQuoteChange={handleConfirmQuoteChange}
            onConfirmTermsChange={handleConfirmTermsChange}
            onReceiveOffersChange={handleReceiveOffersChange}
            confirmQuoteChecked={confirmQuoteChecked}
            confirmTermsChecked={confirmTermsChecked}
            receiveOffersChecked={receiveOffersChecked}
          />
          )}
          <button className={`${formSubmitting && 'loading'} ${styles.newSubmit}`} onClick={formSubmit} type="submit" disabled={!showPayBtn || !isPaymentValid || previousCardFormSubmitting || (order.status === 'quote' && !confirmQuoteChecked) || (['quote', 'in_checkout', 'payment_failed'].includes(order && order.status) && !confirmTermsChecked)}>{t('payment.pay')}</button>
        </>
        )}

      </>
      )}
    </div>
  );
}

export default BankPayment;
