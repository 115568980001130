import { useTranslation } from 'react-i18next';
import '../../../translations/i18n';
import styles from './Address.module.scss';

function Address(props) {
  const { t } = useTranslation('common');
  const { billing, delivery } = props;

  let header = '';
  let address = {};

  if (billing) {
    address = billing;
    header = t('account.billing_address');
  }
  if (delivery) {
    address = delivery;
    header = t('account.delivery_address');
  }

  return (
    <div className={styles.summaryChunk}>
      <h3 className={styles.title}>{header}</h3>
      <ul className={styles.addressList}>
        <li className="gb-mask">
          {address.fname}
          {' '}
          {address.lname}
        </li>
        {address.company && <li className="gb-mask">{address.company}</li>}
        {address.street1 && <li className="gb-mask">{address.street1}</li>}
        {address.street2 && <li className="gb-mask">{address.street2}</li>}
        {address.city && <li className="gb-mask">{address.city}</li>}
        {address.region && <li className="gb-mask">{address.region}</li>}
        {address.postal_code && (
          <li className="gb-mask">{address.postal_code}</li>
        )}
        {address.country && <li className="gb-mask">{address.country}</li>}
        {address.phone && <li className="gb-mask">{address.phone}</li>}
        {address.mobile && address.mobile !== address.phone && (
          <li className="gb-mask">{address.mobile}</li>
        )}
      </ul>
    </div>
  );
}

export default Address;
