import { useTranslation } from 'react-i18next';
import '../../../../translations/i18n';
import styles from './VoucherDownload.module.scss';
import Download from '../../../Analytics/Download/Download';
import { formatDate } from '../../../../utils/OrderUtils';

function VoucherDownload(props) {
  const { t } = useTranslation('common');

  const {
    vouchers,
    bookingCanceled,
    orderStatus,
    tabID,
    orderId,
    productName,
  } = props;

  let cancelled = '';
  let status = '';
  let statusTitle = '';
  let downloadLink = '';

  if (bookingCanceled !== false) {
    cancelled = true;
  }

  if (
    vouchers.status === 'ready_to_download'
    && orderStatus !== 'action_required'
  ) {
    if (vouchers.releaseDate === '' || vouchers.releaseDate < new Date()) {
      status = <h2 className={styles.title}>{t('tickets.status')}</h2>;

      let voucherStatus = vouchers.statusTitle.replaceAll('_', ' ');
      voucherStatus = voucherStatus.charAt(0).toUpperCase() + voucherStatus.slice(1);
      statusTitle = <p className={styles.statusDescription}>{voucherStatus}</p>;
      const voucherPath = Object.values(vouchers.path);
      voucherPath.forEach((path) => {
        if (path.url.includes(vouchers.voucherTd)) {
          let voucherLabel = '';
          if (voucherPath.length > 1) {
            voucherLabel = <span>{path.label}</span>;
          }
          downloadLink = (
            <p className={styles.downloadLinkHolder}>
              {voucherLabel}
              <a
                className={styles.btn}
                href={path.url}
                onClick={Download(
                  vouchers.destination,
                  vouchers.typeTicket,
                  orderId,
                  productName,
                )}
              >
                {t('tickets.download')}
              </a>
            </p>
          );
        }
      });
    } else {
      status = <h2 className={styles.title}>{t('tickets.download_from')}</h2>;
      statusTitle = (
        <p className={styles.statusDescription}>
          {formatDate(vouchers.releaseDate)}
        </p>
      );
      downloadLink = (
        <button className={styles.btn} disabled type="submit">
          {t('tickets.download')}
        </button>
      );
    }
  } else {
    if (vouchers.status !== 'ready_to_download') {
      status = <h2 className={styles.title}>{t('tickets.status')}</h2>;

      let voucherStatus = vouchers.statusTitle.replaceAll('_', ' ');
      voucherStatus = voucherStatus.charAt(0).toUpperCase() + voucherStatus.slice(1);
      statusTitle = <p className={styles.statusDescription}>{voucherStatus}</p>;
    }
    downloadLink = (
      <button className={styles.btn} disabled type="submit">
        {t('tickets.download')}
      </button>
    );
  }

  return (
    <div className={styles.status}>
      {cancelled ? (
        <>
          <h2 className={styles.title}>{t('tickets.status')}</h2>
          <p className={styles.statusDescription}>{t('tickets.canceled')}</p>
          <button className={styles.btn} disabled type="submit">
            {t('tickets.download')}
          </button>
        </>
      ) : (
        <>
          {statusTitle}
          {downloadLink}
        </>
      )}
    </div>
  );
}

export default VoucherDownload;
