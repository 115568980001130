import { useState, useEffect, useCallback } from 'react';
import { format, sub } from 'date-fns';
import API from './phx';
import * as utils from '../utils/OrderUtils';

const getOrderStatusExplainerText = (
  status,
  departureDate,
  paid,
  internalStatus,
  order,
) => {
  const [statusExplainerText, setStatusExplainerText] = useState('');

  let isDisneylandParisSpendingMoney = false;
  let isAllNonDisneylandParisSpendingMoneyBooked = true;

  // check if the disneyland spending money (product ids 281617 and 281619) is causing pending status and if so fetch appropriate explainer text
  order.order_tickets.forEach((ticket) => {
    if (ticket.product_id === '281617' || ticket.product_id === '281619') {
      isDisneylandParisSpendingMoney = true;
    } else if (
      ticket.booking.length > 0
      && ticket.booking[0].booking_status !== 'booked'
      && ticket.booking[0].booking_status !== ''
    ) {
      isAllNonDisneylandParisSpendingMoneyBooked = false;
    }
  });

  let fetchUrl = '';
  // if the status is pending determine whether it is cause products are book on deposit (not fully paid) or book on full payment (fully paid)
  if (status === 'pending') {
    if (
      isDisneylandParisSpendingMoney
      && isAllNonDisneylandParisSpendingMoneyBooked
    ) {
      fetchUrl = utils.appendGeoParam(
        `/text?types=order_status_${status}_disneyland_paris`,
      );
    } else if (paid && internalStatus !== 'action_required') {
      fetchUrl = utils.appendGeoParam(
        `/text?types=order_status_${status}_paid`,
      );
    } else {
      fetchUrl = utils.appendGeoParam(
        `/text?types=order_status_${status}_not_paid`,
      );
    }
  } else {
    fetchUrl = utils.appendGeoParam(`/text?types=order_status_${status}`);
  }

  const fetchData = useCallback(async () => {
    const resp = await API.get(fetchUrl);
    const data = await resp?.data;
    if (status === 'deposit') {
      const paymentDate = format(
        new Date(sub(new Date(departureDate), { days: 42 })),
        utils.getLang() === 'de-de' ? 'dd-MM-yyyy' : 'do MMM yyyy',
      );
      const depositExplainerText = utils.getLang() === 'de-de'
        ? `${`${data.data[0].body} ${paymentDate}`} fällig.`
        : `${`${data.data[0].body} ${paymentDate}`}.`;
      setStatusExplainerText(depositExplainerText);
    } else if (data.data && data.data.length > 0) {
      setStatusExplainerText(data.data[0].body);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return statusExplainerText;
};

export default getOrderStatusExplainerText;
