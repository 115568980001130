/* eslint-disable no-constant-condition */
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import InfoBlock from './InfoBlock/InfoBlock';
import LoginForm from './LoginForm/LoginForm';
import LoginTitle from './LoginTitle/LoginTitle';
import styles from './Login.module.scss';
import { getAppConfig } from '../../config/config';
import API from '../../api/phx';
import * as utils from '../../utils/OrderUtils';

function Login({ isLoggedIn }) {
  // If user is logged in direct to order page
  const config = getAppConfig();
  const orderUrl = utils.prependLangUrlPath(
    `/customer/order/${sessionStorage.orderId}`,
    config.brand,
    config.language_prefix,
  );
  const [isCCNS, setIsCCNS] = useState(false);
  const [cookies, removeCookie] = useCookies();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (isLoggedIn && !isCCNS) {
      setIsLoading(true);
      window.location.replace(orderUrl);
    }
  }, [isLoggedIn]);

  // If order is CCNS log user out
  if (
    location.state
    && location.state.orderStatus === 'customer_contacted_no_sale'
    && !isCCNS
  ) {
    setIsCCNS(true);
    if (cookies.order) {
      removeCookie('order', { path: '/' });
    }
    if (cookies.user) {
      removeCookie('user', { path: '/' });
    }
    localStorage.removeItem('sid');
    sessionStorage.removeItem('sid');
    sessionStorage.removeItem('orderId');

    const deleteUrl = utils.appendGeoParam('/session', config.brand);
    API.delete(deleteUrl);

    // Remove logged in class to parent body.
    const parentBody = document.body;
    if (parentBody) {
      parentBody.classList.remove('logged-in');
    }
    window.history.replaceState({}, document.title);
  }

  return (
    <div className={styles.customerAccounts}>
      {isLoading ? (
        <div className={`${styles.loading} loading`} />
      ) : (
        <>
          <div className={styles.customerAccountsTitle}>
            <LoginTitle />
          </div>
          <div
            className={
              (utils.isATBrand() || config.brand === 'AA')
                ? styles.customerAccountsChunks
                : styles.customerAccountsNonATDChunks
            }
          >
            <div className={styles.customerAccountsChunk}>
              <LoginForm isCCNS />
            </div>
            {(utils.isATBrand() || config.brand === 'AA') && (
              <div className={styles.customerAccountsChunk}>
                <InfoBlock />
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
}

export default Login;
