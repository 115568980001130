import API from './phx';
import * as utils from '../utils/OrderUtils';

const updateOrderDetails = async (orderId, user, confirmQuoteChecked, receiveOffersChecked, confirmTermsChecked) => {
  const fetchUrl = utils.appendGeoParam(`/orders/${orderId}/customer_details`);
  try {
    const response = await API.put(fetchUrl, {
      order_user: user,
      agree_to_email: receiveOffersChecked,
      agree_to_terms: confirmTermsChecked,
      confirm_quote: confirmQuoteChecked,
    });
    return response.data;
  } catch (error) {
    console.error('Error updating order details:', error);
    throw error;
  }
};

export default updateOrderDetails;
