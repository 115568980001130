function CheckboxesDataLayer(checkboxName, checked) {
  const event = {
    event: 'checkbox_click',
    product_download: {
      checkbox_clicked: checkboxName,
      agreed: checked,
    },
  };

  window.dataLayer.push(event);
}

export default CheckboxesDataLayer;
