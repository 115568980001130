import React, {
  useState, useRef, useContext, useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';
import '../../../translations/i18n';
import styles from './UpdateRequiredDetails.module.scss';
import updateTicketAttributes from '../../../api/UpdateTicketAttributes';
import AttributeUpdateContext from '../../../context/AttributeUpdateContext';
import { ReactComponent as TicketIcon } from '../../../svgs/ticket.svg';
import * as utils from '../../../utils/OrderUtils';

function UpdateRequiredDetails(props) {
  const {
    orderId, setIsDetailsBeingEdited, isDetailsBeingEdited, attributes, tabID, user,
  } = props;

  const { t } = useTranslation('common');
  const [isSaving, setIsSaving] = useState(false);
  const attributeUpdateCtx = useContext(AttributeUpdateContext);
  const { attributeData } = attributeUpdateCtx;

  const arraysEqual = (arr1, arr2) => {
    if (arr1.length !== arr2.length) return false;
    return arr1.every((val, index) => val === arr2[index]);
  };

  const isValidValue = (value) => value && value.trim() !== '';

  // Create state to track all attributes and changes, filtering out empty values arrays
  const [ticketAttributes, setTicketAttributes] = useState(
    attributes
      .map((ticket) => {
        const ticketKey = Object.keys(ticket)[0];
        const ticketValues = Object.values(ticket)[0]
          .map((attribute) => ({
            productTitle: attribute.product_title,
            attributeTitle: attribute.title.includes(': ') ? attribute.title.split(': ')[1] : attribute.title,
            attributeId: attribute.attribute_id.split('-')[2],
            orderTicketId: attribute.attribute_id.split('-')[1],
            value: attribute.values.map((value) => ((value.toLowerCase() === 'xx' || value.toLowerCase() === 'x') ? '' : value)),
            originalValue: attribute.values.map((value) => ((value.toLowerCase() === 'xx' || value.toLowerCase() === 'x') ? '' : value)),
            uniqueKey: `${ticketKey}-${attribute.attribute_id}-${attribute.ticket_type}`,
            tip: attribute.tip,
            ticketType: attribute.ticket_type,
            ticketTypeDescription: attribute.ticket_type_description,
            options: attribute.options || [],
            ticketTitle: `${attribute.product_title}${attribute.ticket_type_description
              ? ` ${attribute.ticket_type_description}`
              : ''
            }${attribute.date
              ? ` - ${utils.formatDate(attribute.date)}`
              : ''
            }${attribute.time
              ? ` - ${utils.formatTime(attribute.time)}`
              : ''
            }`,
          }))
          .filter((attr) => attr.value.length > 0);

        return {
          key: ticketKey,
          values: ticketValues,
        };
      })
      .filter((ticket) => ticket.values.length > 0),
  );

  const inputRefs = useRef({});

  const isFormValid = useMemo(() => ticketAttributes.every((ticket) => ticket.values.every((val) => val.value.every(isValidValue))), [ticketAttributes]);

  const hasChanges = useMemo(() => ticketAttributes.some((ticket) => ticket.values.some((val) => !arraysEqual(val.value, val.originalValue))), [ticketAttributes]);

  const handleInputChange = (uniqueKey, index, newValue) => {
    setTicketAttributes((prevAttributes) => prevAttributes.map((ticket) => ({
      ...ticket,
      values: ticket.values.map((val) => (val.uniqueKey === uniqueKey
        ? {
          ...val,
          value: val.value.map((v, i) => (i === index ? newValue : v)),
        }
        : val)),
    })));
  };

  const handleSave = async (e) => {
    e.preventDefault();

    if (!isFormValid || !hasChanges) return;

    setIsSaving(true);

    try {
      const updatePromises = ticketAttributes.flatMap((attribute) => attribute.values
        .filter((value) => !arraysEqual(value.value, value.originalValue))
        .map((value) => updateTicketAttributes(
          orderId,
          user,
          [{
            order_ticket_id: value.orderTicketId,
            attributes_array: [{
              attribute_id: value.attributeId,
              values: value.value.filter(isValidValue),
            }],
          }],
        )));

      if (updatePromises.length > 0) {
        await Promise.all(updatePromises);
        setIsDetailsBeingEdited(!isDetailsBeingEdited);
        attributeData('attributes-updated');
      }
    } catch (error) {
      console.error('Error updating ticket attributes:', error);
      attributeData('attributes-not-updated');
    } finally {
      setIsSaving(false);
    }
  };

  // Group attributes by their title and then by ticket type, filtering out empty groups
  const groupedAttributes = useMemo(() => {
    const groups = {};
    ticketAttributes.forEach((ticket) => {
      ticket.values
        .filter((value) => value.value.length > 0)
        .forEach((value) => {
          if (!groups[value.ticketTitle]) {
            groups[value.ticketTitle] = {};
          }
          if (!groups[value.ticketTitle][value.ticketType]) {
            groups[value.ticketTitle][value.ticketType] = [];
          }
          groups[value.ticketTitle][value.ticketType].push({
            ...value,
            ticketKey: ticket.key,
          });
        });
    });

    // Filter out ticket titles with no attributes or empty values
    const filteredGroups = Object.entries(groups).reduce((acc, [title, ticketTypes]) => {
      const hasValidAttributes = Object.values(ticketTypes).some((attrGroup) => attrGroup.length > 0 && attrGroup.some((attr) => attr.value.length > 0));
      if (hasValidAttributes) {
        acc[title] = ticketTypes;
      }
      return acc;
    }, {});

    return filteredGroups;
  }, [ticketAttributes]);

  return (
    <div id={tabID} className={styles.quote}>
      <h2 className={styles.title}>{t('update_required_details')}</h2>
      {Object.entries(groupedAttributes).map(([attributeTitle, ticketTypeGroups]) => (
        <div key={attributeTitle} className={styles.attributeSection}>
          <h3 className={styles.ticketTitle}>
            <TicketIcon />
            {' '}
            {attributeTitle}
          </h3>
          {Object.entries(ticketTypeGroups)
            .filter(([_, group]) => group.length > 0 && group.some((attr) => attr.value.length > 0))
            .map(([ticketType, attributeGroup]) => {
              const maxValuesForAttribute = Math.max(
                ...attributeGroup.map((attr) => attr.value.length),
              );

              return (
                <div key={ticketType} className={styles.ticketTypeSection}>
                  {Array.from({ length: maxValuesForAttribute }, (_, valueIndex) => (
                    <div key={`index-${valueIndex}`} className={styles.indexGroup}>
                      <h4 className={styles.subtitle}>{`${ticketType || t('Passenger')} ${valueIndex + 1}`}</h4>
                      <div className={attributeGroup.length > 1 ? styles.personGroup : ''}>
                        {attributeGroup
                          .filter((attribute) => attribute.value.length > 0)
                          .map((attribute) => {
                            if (valueIndex >= attribute.value.length) return null;
                            const val = attribute.value[valueIndex];
                            const uniqueInputKey = `${attribute.uniqueKey}-${valueIndex}`;
                            const isInvalid = !isValidValue(val);

                            return (
                              <div key={uniqueInputKey} className={styles.attributeGroup}>
                                <label className={styles.label} htmlFor="name">
                                  {attribute.attributeTitle}
                                  <span title="Required" className={styles.required}>*</span>
                                </label>
                                {attribute.options && attribute.options.length > 0 ? (
                                  <select
                                    ref={(el) => {
                                      if (!inputRefs.current[attribute.uniqueKey]) {
                                        inputRefs.current[attribute.uniqueKey] = [];
                                      }
                                      inputRefs.current[attribute.uniqueKey][valueIndex] = el;
                                    }}
                                    className={`${styles.input} ${isInvalid ? styles.error : ''}`}
                                    value={isInvalid ? '' : val}
                                    onChange={(e) => {
                                      handleInputChange(attribute.uniqueKey, valueIndex, e.target.value);
                                      e.target.focus();
                                    }}
                                    onKeyDown={(e) => {
                                      e.stopPropagation();
                                    }}
                                  >
                                    <option value="">
                                      {attribute.tip ? attribute.tip : `Select ${attribute.attributeTitle}`}
                                    </option>
                                    {attribute.options.map((option) => (
                                      <option key={option} value={option}>
                                        {option}
                                      </option>
                                    ))}
                                  </select>
                                ) : (
                                  <input
                                    ref={(el) => {
                                      if (!inputRefs.current[attribute.uniqueKey]) {
                                        inputRefs.current[attribute.uniqueKey] = [];
                                      }
                                      inputRefs.current[attribute.uniqueKey][valueIndex] = el;
                                    }}
                                    className={`${styles.input} ${isInvalid ? styles.error : ''}`}
                                    type="text"
                                    value={isInvalid ? '' : val}
                                    onChange={(e) => {
                                      handleInputChange(attribute.uniqueKey, valueIndex, e.target.value);
                                      e.target.focus();
                                    }}
                                    onKeyDown={(e) => {
                                      e.stopPropagation();
                                    }}
                                    placeholder={attribute.tip ? attribute.tip : attribute.attributeTitle}
                                  />
                                )}
                                {isInvalid && (
                                  <p className={styles.errorMessage}>
                                    {`Please provide ${attribute.attributeTitle}`}
                                  </p>
                                )}
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  ))}
                </div>
              );
            })}
        </div>
      ))}

      <button
        className={`${styles.btn} ${isSaving ? 'loading' : ''}`}
        type="submit"
        onClick={handleSave}
        disabled={!isFormValid || !hasChanges}
      >
        {t('update_required_details')}
      </button>
    </div>
  );
}

export default UpdateRequiredDetails;
