import { useState, useEffect, useCallback } from 'react';
import API from '../api/phx';

const useCountries = () => {
  const [countries, setCountries] = useState([]);

  const fetchData = useCallback(async () => {
    try {
      const resp = await API.get('/countries');
      // Check if resp and resp.data exist before accessing
      if (resp && resp.data && resp.data.data) {
        setCountries(resp.data.data);
      } else {
        console.error('Unexpected response structure:', resp);
        setCountries([]);
      }
    } catch (error) {
      console.error('Error fetching countries:', error);
      setCountries([]);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return { countries };
};

export default useCountries;
