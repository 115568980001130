import { useTranslation } from 'react-i18next';
import PaymentDetails from './PaymentDetails/PaymentDetails';
import '../../../translations/i18n';
import styles from './Payment.module.scss';

function Payment(props) {
  const { t } = useTranslation('common');
  const {
    tabID, order, failureMessage, setFailureMessage, payAmount,
  } = props;

  return (
    <div id={tabID} className={styles.payment}>
      <h2 className={styles.title}>{t('payment.payment_tab')}</h2>
      <PaymentDetails
        order={order}
        failureMessage={failureMessage}
        setFailureMessage={setFailureMessage}
        payAmount={payAmount}
      />
    </div>
  );
}

export default Payment;
