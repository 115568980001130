import parse from 'html-react-parser';
import { useTranslation } from 'react-i18next';
import '../../../translations/i18n';
import { useState } from 'react';
import { Tooltip } from 'react-tooltip';
import * as utils from '../../../utils/OrderUtils';
import { getAppConfig } from '../../../config/config';
import styles from './Tickets.module.scss';
import Download from '../../Analytics/Download/Download';

function Tickets(props) {
  const {
    id, img, title, checkoutDate, date, time, currency, vouchers, price, tickets, orderStatus, whereShown, attributes, quantity, subtitle, bookOnDeposit, bookingStatus, destination, typeTicket, orderId, isAccommodationQuote,
    productId,
  } = props;

  const { t } = useTranslation('common');
  const config = getAppConfig();

  let voucherPath;
  let voucherLabel;
  const voucherUrl = [];
  const voucherStatus = [];
  const [newYorkPassGuide, setNewYorkPassGuide] = useState(false);
  const [newYorkGuide, setNewYorkGuide] = useState(false);
  const downladStatus = [
    'quote',
    'in_checkout',
    'canceled',
    'payment_failed',
    'customer_contacted_no_sale',
    'awaiting_paypal',
  ];
  const buttonClass = config.brand === 'DSD' ? styles.dsdStatusButton : styles.statusButton;

  if (vouchers) {
    let voucherStatusTitle;
    if (
      vouchers
      && vouchers.status === 'ready_to_download'
      && !downladStatus.includes(orderStatus)
    ) {
      const today = new Date().getTime();
      const releaseDate = new Date(vouchers.release_date).getTime();
      if (vouchers.release_date.length === 0 || releaseDate <= today) {
        voucherStatusTitle = (
          <p key={vouchers.status_title} className={styles.statusTitle}>
            {t(vouchers.status_title)}
          </p>
        );
        voucherStatus.push(voucherStatusTitle);
        voucherPath = Object.values(vouchers.path);
        voucherPath.forEach((path) => {
          if (voucherPath.length > 1) {
            voucherLabel = (
              <span key={path.label} className={styles.statusSpan}>
                {path.label}
              </span>
            );
          }
          const url = (
            <a
              key={t('tickets.download')}
              href={path.url}
              className={buttonClass}
              onClick={Download(destination, typeTicket, orderId, title)}
            >
              {t('tickets.download')}
            </a>
          );
          voucherUrl.push(url);
        });
        voucherStatus.push(voucherLabel);
        voucherStatus.push(voucherUrl);
      } else {
        const download = (
          <>
            <p key={t('tickets.download_from')} className={styles.statusTitle}>
              {t('tickets.download_from')}
              {' '}
              {utils.formatDate(vouchers.release_date)}
            </p>
            <p className={styles.statusDownload}>
              <span className={`${buttonClass} disabled`}>
                {t('tickets.download')}
              </span>
            </p>
          </>
        );
        voucherStatus.push(download);
      }
    } else {
      if (vouchers.status !== 'ready_to_download') {
        voucherStatus.push(
          <p key={t(vouchers.status_title)} className={styles.statusTitle}>
            {t(vouchers.status_title)}
          </p>,
        );
      } else if (orderStatus === 'canceled') {
        voucherStatus.push(
          <p
            key={t('tickets.booking_cancelled')}
            className={styles.statusTitle}
          >
            {t('tickets.booking_cancelled')}
          </p>,
        );
      }
      voucherStatus.push(
        <p key={t('tickets.download')} className={styles.statusDownload}>
          <span className={`${buttonClass} disabled`}>
            {t('tickets.download')}
          </span>
        </p>,
      );
    }

    if (
      vouchers.data
      && vouchers.data.new_york_pass_guide.length > 0
      && vouchers.status === 'ready_to_download'
    ) {
      setNewYorkPassGuide(true);
    }
    if (
      vouchers.data
      && vouchers.data.new_york_guide.length > 0
      && vouchers.status === 'ready_to_download'
    ) {
      setNewYorkGuide(true);
    }
  }

  let ticketClass = '';
  if (whereShown === 'hotelTicketsPage') {
    ticketClass = `${styles.hotelTicketItem}`;
  } else {
    ticketClass = `${styles.ticketItem}`;
  }

  const blackFridayNids = ['275879', '321273', '321270', '321271', '254384', '296574', '321261', '321262', '234084', '296586', '321267', '321268', '321628', '321626', '321629', '321627', '254503', '321618', '281016', '321623', '254504', '321624', '281017', '321625', '341595', '341601', '341606'];

  return (
    <li className={ticketClass} key={id}>
      <div className={styles.image}>
        <img alt="" src={img} />
      </div>
      <div className={styles.info}>
        <h3 className={styles.title}>{blackFridayNids.includes(productId) && title.includes(' - ') ? title.split(' - ')[0] : title}</h3>
        {checkoutDate && checkoutDate.length > 0 ? (
          <p className={styles.dates}>
            {t('tickets.check-in-date')}
            {' '}
            {utils.formatDate(date)}
            {' '}
            {t('tickets.check-out-date')}
            {' '}
            {utils.formatDate(checkoutDate)}
          </p>
        ) : (
          <p className={styles.dates}>
            {utils.formatDate(date)}
            {' '}
            {utils.formatTime(time)}
          </p>
        )}
        {tickets && (
          <p className={styles.number}>
            {tickets
              && tickets.map((ticket) => (
                <span key={ticket}>
                  {ticket}
                  {' '}
                </span>
              ))}
          </p>
        )}
        {subtitle && quantity && (
          <p className={styles.subtitle}>{`${quantity} x ${subtitle}`}</p>
        )}
        {price && price !== 0 ? (
          <p className={styles.price}>
            {parse(utils.getCurrencyCode(currency))}
            {utils.formatPrice(price)}
            {isAccommodationQuote && (
            <>
              {' '}
              <span className={styles.tooltipTitle} data-tooltip-id="ticket-validity-text" data-tooltip-html="Hotel prices on our website can change at any time, even after you've added them to your cart or they have been quoted by a member of our team. The final price will be confirmed once the supplier confirms your reservation. If you have questions about pricing, please contact us.’" data-tooltip-place="bottom"> i </span>
              <Tooltip id="ticket-validity-text" className={styles.tooltipBody} />
            </>
            )}
          </p>
        ) : (
          <p className={styles.price}>{t('FREE')}</p>
        )}
        {attributes
          && attributes.map((attribute) => (
            <p key={Object.keys(attribute)} className={styles.price}>
              <b>{Object.keys(attribute)}</b>
              :
              {' '}
              <em>
                <span
                  className={
                    Object.values(attribute)[0].indexOf('Required') > -1
                      ? styles.missingAttribute
                      : ''
                  }
                >
                  {Object.values(attribute)}
                </span>
              </em>
            </p>
          ))}
        {newYorkPassGuide && (
          <p className={styles.guide}>
            <a href="https://www.smartdestinations.com/pdf/getGuideBook.ep?productCode=Nyc_Prod_Exp_c3">
              {t('tickets.newyork_pass_guide')}
            </a>
          </p>
        )}
        {newYorkGuide && (
          <p className={styles.guide}>
            <a href="https://www.attraction-tickets-direct.co.uk/sites/default/files/guidebook_New_York_Pass_en.pdf">
              {t('tickets.newyork_guide')}
            </a>
          </p>
        )}
      </div>
      {vouchers && (
        <div className={styles.status}>
          <div>{voucherStatus}</div>
        </div>
      )}
    </li>
  );
}

export default Tickets;
