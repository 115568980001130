import { useEffect, useState, useContext } from 'react';
import parse from 'html-react-parser';
import { useTranslation } from 'react-i18next';
import API from '../../../../api/phx';
import * as utils from '../../../../utils/OrderUtils';
import '../../../../translations/i18n';
import styles from './GiftCardPayment.module.scss';
import Payments from '../../../Analytics/Payments/Payments';
import PaymentContext from '../../../../context/PaymentContext';
import Checkboxes from '../Checkboxes/Checkboxes';
import updateOrderDetails from '../../../../api/UpdateOrderDetails';

function GiftCardPayment(props) {
  const { order } = props;

  const { t } = useTranslation('common');

  const paymentCtx = useContext(PaymentContext);
  const { paymentData } = paymentCtx;

  const [giftCardNumber, setGiftCardNumber] = useState('');
  const [formIsValid, setFormIsValid] = useState();
  const [giftCardBalance, setGiftCardBalance] = useState();
  const [orderBalanceAfterPay, setOrderBalanceAfterPay] = useState();
  const [giftCardBalanceAfterPay, setGiftCardBalanceAfterPay] = useState();
  const [giftCardPaymentData, setGiftCardPaymentData] = useState([]);
  const [errMsg, setErrMsg] = useState();
  const [errMsgOrder, setErrMsgOrder] = useState();
  const [gcFormSubmitting, setGcFormSubmitting] = useState(false);

  const lang = utils.getLang();

  const [confirmQuoteChecked, setConfirmQuoteChecked] = useState(false);
  const [confirmTermsChecked, setConfirmTermsChecked] = useState(order && order.agree_terms === '1');
  const [receiveOffersChecked, setReceiveOffersChecked] = useState(order && order.agree_marketing === '1');

  const handleConfirmQuoteChange = (checked) => {
    setConfirmQuoteChecked(checked);
  };

  const handleConfirmTermsChange = (checked) => {
    setConfirmTermsChecked(checked);
  };

  const handleReceiveOffersChange = (checked) => {
    setReceiveOffersChecked(checked);
  };

  // Check form is valid
  useEffect(() => {
    setFormIsValid(giftCardNumber.trim().length >= 1);
  }, [giftCardNumber]);

  const applyGiftCard = async (e) => {
    updateOrderDetails(order.id, order.owner, confirmQuoteChecked, receiveOffersChecked, confirmTermsChecked)
      .catch((error) => {
        console.error('Error updating order details:', error);
      });
    e.preventDefault();
    setGcFormSubmitting(true);

    try {
      const result = await API.post(
        `/payments/gift_card?geo=${lang}`,
        giftCardPaymentData,
      );
      const orderApiPath = `/orders/${giftCardPaymentData.order_id}?geo=${lang}`;
      const orderObj = await API.get(orderApiPath);
      try {
        await API.put(orderApiPath, orderObj.data);
        paymentData(
          'payment-success',
          String(Number(order.balance) - Number(giftCardPaymentData.amount)),
        );
        Payments(order, giftCardPaymentData.amount, 'gift-card');
      } catch (err) {
        setErrMsgOrder('Failed to save order after gift cards applied');
        console.error('Failed to save order after gift cards applied".', err);
        setGcFormSubmitting(false);
      }
    } catch (err) {
      setErrMsgOrder('Failed to capture payment');
      console.error('Failed to capture payment.', err);
      setGcFormSubmitting(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (formIsValid) {
      if (giftCardBalance) setGiftCardBalance('');
      if (orderBalanceAfterPay) setOrderBalanceAfterPay('');
      if (giftCardBalanceAfterPay) setGiftCardBalanceAfterPay('');

      const currencyCode = order.currency;
      const orderId = order.id;

      const datauri = `/gift_cards/check?geo=${lang}`;

      try {
        await API.post(datauri, {
          serial: giftCardNumber,
          order_id: orderId,
        }).then((response) => {
          const giftCardId = response.data.gift_card_id;
          const orderBalance = order.balance;
          setErrMsg('');
          setErrMsgOrder('');

          let payAmount = '0';

          setGiftCardBalance(response.data.balance);

          if (Number(orderBalance) >= Number(response.data.balance)) {
            payAmount = response.data.balance;
            setOrderBalanceAfterPay(
              Number(orderBalance) - Number(response.data.balance),
            );
            setGiftCardBalanceAfterPay('0');
          } else {
            payAmount = orderBalance;
            setGiftCardBalanceAfterPay(
              Number(response.data.balance) - Number(orderBalance),
            );
            setOrderBalanceAfterPay('0');
          }

          const data = {
            order_id: orderId,
            currency: response.data.currency,
            amount: Number(payAmount).toFixed(2),
            description: 'Customer account payment',
            gift_card_id: giftCardId,
          };
          setGiftCardPaymentData(data);
        });
      } catch (error) {
        console.error(error.response.data);
        setErrMsg(error.response.data.err_desc);
      }
    }
  };

  return (
    <div className={styles.giftcard}>
      <form id="order-payment-gift-card" onSubmit={handleSubmit}>
        <fieldset className={styles.fieldset}>
          <div className={styles.field}>
            <label className={styles.label} htmlFor="giftCardNumber">
              {t('payment.giftcard.card_number')}
              {' '}
              <span title="Required" className={styles.required}>
                *
              </span>
            </label>
            <input
              className={styles.input}
              type="text"
              id="giftCardNumber"
              onChange={(e) => setGiftCardNumber(e.target.value)}
              value={giftCardNumber}
              required
            />
            {errMsg && <div>{errMsg}</div>}
          </div>
          <button className={styles.verify} type="submit">
            {t('payment.giftcard.verify_card')}
          </button>
        </fieldset>
      </form>
      <div>
        {/* { address.postal_code && <li>{address.postal_code}</li> } */}
        {giftCardBalance && (
          <p>
            {t('payment.giftcard.balance')}
            :
            {parse(utils.getCurrencyCode(order.currency))}
            {utils.formatPrice(giftCardBalance)}
          </p>
        )}
        {orderBalanceAfterPay && (
          <p>
            {t('payment.giftcard.order_balance')}
            :
            {parse(utils.getCurrencyCode(order.currency))}
            {utils.formatPrice(orderBalanceAfterPay)}
          </p>
        )}
        {giftCardBalanceAfterPay && (
          <p>
            {t('payment.giftcard.card_balance')}
            :
            {parse(utils.getCurrencyCode(order.currency))}
            {utils.formatPrice(giftCardBalanceAfterPay)}
          </p>
        )}
        {['quote', 'in_checkout', 'payment_failed'].includes(order && order.status) && giftCardBalance && (
          <Checkboxes
            order={order}
            onConfirmQuoteChange={handleConfirmQuoteChange}
            onConfirmTermsChange={handleConfirmTermsChange}
            onReceiveOffersChange={handleReceiveOffersChange}
            confirmQuoteChecked={confirmQuoteChecked}
            confirmTermsChecked={confirmTermsChecked}
            receiveOffersChecked={receiveOffersChecked}
          />
        )}
        { giftCardBalance
          && <button className={`${gcFormSubmitting && 'loading'} ${styles.newSubmit}`} id="gift-card-apply" disabled={(order.status === 'quote' && !confirmQuoteChecked) || (['quote', 'in_checkout', 'payment_failed'].includes(order && order.status) && !confirmTermsChecked)} onClick={applyGiftCard} type="button">{t('payment.giftcard.apply_card')}</button>}
        { errMsgOrder && <div>{errMsgOrder}</div> }
      </div>
    </div>
  );
}

export default GiftCardPayment;
