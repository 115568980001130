import { useCallback, useEffect, useState } from 'react';
import parse from 'html-react-parser';
import API from './gapi';
import { getLang, isATBrand } from '../utils/OrderUtils';
import { getAppConfig } from '../config/config';

const config = getAppConfig();

function GetTermsAndPrivacy({ id }) {
  const [bodyText, setBodyText] = useState('');

  const fetchData = useCallback(async () => {
    let fetchUrl = '';
    if (isATBrand()) {
      fetchUrl = `${getLang()}/api/${id}`;
    } else if (config.site_name === 'AmericanAttractions') {
      fetchUrl = `/get_privacy_content/${id}`;
    } else {
      fetchUrl = `/api/text?types=${id}`;
    }

    try {
      const resp = await API.get(fetchUrl);
      const data = await resp?.data;
      if (isATBrand()) {
        setBodyText(data.intro[0].value);
      } else if (config.site_name === 'AmericanAttractions') {
        setBodyText(data);
      } else {
        setBodyText(data.data[0].body);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }, [id]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return parse(bodyText);
}

export default GetTermsAndPrivacy;
