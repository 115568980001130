import API from './phx';
import * as utils from '../utils/OrderUtils';

const updateTicketAttributes = async (orderId, user, attributesArray) => {
  try {
    const updates = attributesArray.map(async (attribute) => {
      if (!attribute?.order_ticket_id) {
        throw new Error('Invalid attribute: missing order_ticket_id');
      }

      const url = utils.appendGeoParam(`/orders/${orderId}/order_tickets/${attribute.order_ticket_id}/attributes`);

      return API.put(url, {
        order_user: user,
        attributes: attribute.attributes_array || [],
      });
    });

    return await Promise.all(updates);
  } catch (error) {
    console.error('Ticket attribute update failed:', error);
    throw error;
  }
};

export default updateTicketAttributes;
