import AppConfigReducerInitialState from '../reducers/AppConfigReducer/AppConfigReducerInitialState';

/** Get the app config. */
export const getAppConfig = () => {
  // Get the site config from the Drupal settings.
  let appConfig = AppConfigReducerInitialState;

  if (
    typeof window.drupalSettings !== 'undefined'
    && window.drupalSettings.cust_account_app !== 'undefined'
  ) {
    appConfig = window.drupalSettings.cust_account_app;
  }

  return appConfig;
};

/** Get the API Base Url from the the app config. */
export const getApiBaseUrl = () => {
  let apiBaseUrl = `//${window.location.hostname}/api`;
  const appConfig = getAppConfig();
  if (
    typeof appConfig !== 'undefined'
    && appConfig.api_base_url !== 'undefined'
  ) {
    apiBaseUrl = appConfig.api_base_url;
  }

  return apiBaseUrl;
};

/** Get the API Base Url from the the app config. */
export const getGapiBaseUrl = () => {
  const apiBaseUrl = `//${window.location.hostname}`;
  return apiBaseUrl;
};

/** Get the site config. */
export const getSiteConfig = () => {
  const langs = {
    depositEnabledLangs: ['en', 'en-ie'],
    giroPayEnabledLangs: ['de-de'],
    paypalEnableLangs: ['en', 'en-ie', 'de-de'],
    idealPayEnabledLangs: ['en-nl'],
    idealPayEnabledCountry: 'Netherlands',
  };
  return langs;
};

const domainUrl = window.location.hostname;

export const getTermsAndPolicyConfig = () => {
  let termsAndPolicyCofig = {};
  if (domainUrl.match(/americanattractions|aa/)) {
    termsAndPolicyCofig = {
      termsLink: '493',
      policyLink: '494',
    };
  } else if (domainUrl.match(/seaworldparks|sw|seaworld/)) {
    termsAndPolicyCofig = {
      termsLink: 'booking_conditions',
      policyLink: 'privacy_policy',
    };
  } else if (domainUrl.match(/universal-bookings|uo|orlando|universal/)) {
    termsAndPolicyCofig = {
      termsLink: 'terms_conditions',
      policyLink: 'privacy_policy',
    };
  } else if (domainUrl.match(/attractiontickets|global|localhost/)) {
    termsAndPolicyCofig = {
      termsLink: '4239',
      policyLink: '4240',
    };
  } else {
    termsAndPolicyCofig = {
      termsLink: 'terms_conditions',
      policyLink: 'privacy_policy',
    };
  }
  return termsAndPolicyCofig;
};
