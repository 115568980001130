import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { applePay } from './ApplePay';
import styles from './ApplePay.module.scss';
import { getLang } from '../../../../utils/OrderUtils';
import PaymentContext from '../../../../context/PaymentContext';
import Checkboxes from '../Checkboxes/Checkboxes';

function ApplePayTab({ order, payAmount }) {
  const { t } = useTranslation('common');

  const paymentCtx = useContext(PaymentContext);
  const { paymentData } = paymentCtx;

  const [confirmQuoteChecked, setConfirmQuoteChecked] = useState(false);
  const [confirmTermsChecked, setConfirmTermsChecked] = useState(order && order.agree_terms === '1');
  const [receiveOffersChecked, setReceiveOffersChecked] = useState(order && order.agree_marketing === '1');

  const handleConfirmQuoteChange = (checked) => {
    setConfirmQuoteChecked(checked);
  };

  const handleConfirmTermsChange = (checked) => {
    setConfirmTermsChecked(checked);
  };

  const handleReceiveOffersChange = (checked) => {
    setReceiveOffersChecked(checked);
  };

  return (
    <div className={styles.applepay}>
      <p>{t('Apple_pay_info')}</p>
      {['quote', 'in_checkout', 'payment_failed'].includes(order && order.status) && (
        <Checkboxes
          order={order}
          onConfirmQuoteChange={handleConfirmQuoteChange}
          onConfirmTermsChange={handleConfirmTermsChange}
          onReceiveOffersChange={handleReceiveOffersChange}
          confirmQuoteChecked={confirmQuoteChecked}
          confirmTermsChecked={confirmTermsChecked}
          receiveOffersChecked={receiveOffersChecked}
        />
      )}
      {/* eslint-disable-next-line */}
      <button className={(order.status === 'quote' && !confirmQuoteChecked) || (['quote', 'in_checkout', 'payment_failed'].includes(order && order.status) && !confirmTermsChecked) ? styles.disabledApplePayButton : styles.applePayButton} disabled={(order.status === 'quote' && !confirmQuoteChecked) || (['quote', 'in_checkout', 'payment_failed'].includes(order && order.status) && !confirmTermsChecked)} onClick={() => applePay(order, payAmount, paymentData, confirmQuoteChecked, confirmTermsChecked, receiveOffersChecked)}></button>
    </div>
  );
}

export default ApplePayTab;
