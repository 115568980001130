import React, { useState, useMemo } from 'react';

const AttributeUpdateContext = React.createContext({
  attributeUpdate: false,
  attributeStatus: '',
  attributeData: (data) => { },
  forceAttributeFetch: false,
});

export function AttributeUpdateContextProvider(props) {
  const { children } = props;
  const [attributeUpdate, setAttributeUpdate] = useState(false);
  const [attributeStatus, setAttributeStatus] = useState({});
  const [forceAttributeFetch, setForceAttributeFetch] = useState(false);

  const attributeDataHandler = (status) => {
    if (status) {
      setAttributeStatus(status);
      setAttributeUpdate(true);
      setForceAttributeFetch(!forceAttributeFetch);
    }
  };

  const attributeProviderValue = useMemo(
    () => ({
      attributeUpdate,
      attributeStatus,
      attributeData: attributeDataHandler,
      forceAttributeFetch,
    }),
    [attributeUpdate, attributeStatus, forceAttributeFetch],
  );

  return (
    <AttributeUpdateContext.Provider value={attributeProviderValue}>
      {children}
    </AttributeUpdateContext.Provider>
  );
}

export default AttributeUpdateContext;
