import { useTranslation } from 'react-i18next';
import '../../../translations/i18n';
import { Tooltip } from 'react-tooltip';
import { getAppConfig } from '../../../config/config';
import styles from './HeaderInfo.module.scss';
import * as utils from '../../../utils/OrderUtils';
import getOrderStatusExplainerText from '../../../api/StatusExplainerTextAPI';

const config = getAppConfig();

function HeaderInfo(props) {
  const {
    orderId,
    status,
    address,
    departureDate,
    paid,
    internalStatus,
    order,
  } = props;
  const orderStatus = utils.formatCustomerOrderStatus(status);

  const { t } = useTranslation('common');

  // get the explainer standard text responding to the customer order status
  const explainerText = getOrderStatusExplainerText(
    status,
    departureDate,
    paid,
    internalStatus,
    order,
  );

  let name = '';
  if (
    config.brand === 'DSD'
    && order?.lead_passenger_name
    && order?.lead_passenger_name !== ''
  ) {
    name = order.lead_passenger_name;
  } else {
    name = utils.formatName(address.fname, address.lname);
  }

  return (
    <>
      <h1 className={styles.accountsTitle}>
        {t('account.header.order')}
        {' '}
        {orderId}
      </h1>
      <h2
        className={
          config.brand === 'DSD' ? styles.passengerAndDate : styles.accountsName
        }
      >
        {config.brand === 'DSD' && (
          <strong>{`${t('account.header.lead_passenger')}: `}</strong>
        )}
        {name}
      </h2>
      {config.brand === 'DSD' && (
        <h2 className={styles.passengerAndDate}>
          <strong>{`${t('account.header.departure_date')}: `}</strong>
          {utils.formatDate(departureDate)}
        </h2>
      )}

      <>
        <span className={styles.accountsStatus}>
          {t('account.header.status')}
          :
          {' '}
          {t(orderStatus)}
          {' '}
        </span>
        {explainerText && (
          <>
            <span
              className={styles.tooltipTitle}
              data-tooltip-id="status-explainer-text"
              data-tooltip-html={explainerText}
              data-tooltip-place="bottom"
            >
              {' '}
              i
              {' '}
            </span>
            <Tooltip
              id="status-explainer-text"
              className={styles.tooltipBody}
            />
          </>
        )}
      </>

      {config.faq_url !== '' && (
        <p className={styles.accountsIntro}>
          {t('account.header.faq_text')}
          {' '}
          <a href={config.faq_url} target="_blank" rel="noreferrer">{t('account.header.faq_link_text')}</a>
        </p>
      )}
    </>
  );
}

export default HeaderInfo;
