import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import '../../../../translations/i18n';
import {
  Frames, CardNumber, ExpiryDate, Cvv,
} from 'frames-react';
import styles from './GiroPay.module.scss';
import {
  CheckoutDotComAPIPayment,
  CheckoutDotComAPISettings,
} from '../../../../api/CheckoutDotComAPI';
import * as utils from '../../../../utils/OrderUtils';
import Payments from '../../../Analytics/Payments/Payments';
import Checkboxes from '../Checkboxes/Checkboxes';
import updateOrderDetails from '../../../../api/UpdateOrderDetails';

function Giropay(props) {
  const { payAmount, order } = props;
  const { t } = useTranslation('common');
  const [redirectUrl, setRedirectUrl] = useState('');

  const [confirmQuoteChecked, setConfirmQuoteChecked] = useState(false);
  const [confirmTermsChecked, setConfirmTermsChecked] = useState(order && order.agree_terms === '1');
  const [receiveOffersChecked, setReceiveOffersChecked] = useState(order && order.agree_marketing === '1');

  const handleConfirmQuoteChange = (checked) => {
    setConfirmQuoteChecked(checked);
  };

  const handleConfirmTermsChange = (checked) => {
    setConfirmTermsChecked(checked);
  };

  const handleReceiveOffersChange = (checked) => {
    setReceiveOffersChecked(checked);
  };

  const frames = (
    <div className={`${styles.giropayFrame} gb-mask`}>
      <iframe
        title="giropay"
        id="giropay-iframe"
        name="giropay_frame"
        src=""
        height="0"
      />
    </div>
  );

  const GiroPayCaptureHandler = (e) => {
    updateOrderDetails(order.id, order.owner, confirmQuoteChecked, receiveOffersChecked, confirmTermsChecked)
      .catch((error) => {
        console.error('Error updating order details:', error);
      });
    e.preventDefault();
    CheckoutDotComAPIPayment(
      '',
      'giropay',
      payAmount,
      order.currency,
      order.email,
      order.id,
    ).then((redirectUrls) => {
      setRedirectUrl(redirectUrls);
      window.location.href = redirectUrls;
    });
    Payments(order, payAmount, 'giro-pay');
  };
  return (
    <div className={styles.giropay}>
      {frames}
      <p className={styles.info}>{t('payment.giropay_text')}</p>
      {['quote', 'in_checkout', 'payment_failed'].includes(order && order.status) && (
        <Checkboxes
          order={order}
          onConfirmQuoteChange={handleConfirmQuoteChange}
          onConfirmTermsChange={handleConfirmTermsChange}
          onReceiveOffersChange={handleReceiveOffersChange}
          confirmQuoteChecked={confirmQuoteChecked}
          confirmTermsChecked={confirmTermsChecked}
          receiveOffersChecked={receiveOffersChecked}
        />
      )}
      <button className={styles.submit} disabled={(order.status === 'quote' && !confirmQuoteChecked) || (['quote', 'in_checkout', 'payment_failed'].includes(order && order.status) && !confirmTermsChecked)} onClick={GiroPayCaptureHandler} type="submit">{t('payment.giropay_button')}</button>
    </div>
  );
}

export default Giropay;
