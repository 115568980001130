export const getCountryCode = () => {
  const langPrefix = window.location.href.split('/')[3];
  let countryCode;

  switch (langPrefix) {
    case 'de':
      countryCode = 'DE';
      break;

    case 'au':
      countryCode = 'AU';
      break;

    case 'us':
      countryCode = 'US';
      break;

    case 'en-nl':
      countryCode = 'NL';
      break;

    case 'en-ie':
      countryCode = 'IE';
      break;

    default:
      countryCode = 'GB';
  }

  return countryCode;
};

export const domainLabel = () => {
  let config = {};
  const domainUrl = window.location.hostname;
  if (domainUrl.match(/americanattractions|aa/)) {
    config = {
      externalPaymentLabel: 'American Attractions',
    };
  } else if (domainUrl.match(/seaworldparks|sw|seaworld/)) {
    config = {
      externalPaymentLabel: 'SeaWorld Parks & Entertainment',
    };
  } else if (domainUrl.match(/universal-bookings|uo|orlando|universal/)) {
    config = {
      externalPaymentLabel: 'Universal Studios',
    };
  } else if (domainUrl.match(/attractiontickets|global|localhost/)) {
    config = {
      externalPaymentLabel: 'Attractiontickets.com',
    };
  }
  return config;
};

export const loading = (state = true) => {
  if (state) {
    // Create the parent div
    const parentDiv = document.createElement('div');
    parentDiv.className = 'loading_overlay'; // Assign a class to the parent div

    // Create the child div
    const childDiv = document.createElement('div');
    childDiv.className = 'loading_circle'; // Assign a class to the child div

    // Append the child div to the parent div
    parentDiv.appendChild(childDiv);

    // Append the parent div to the document body (you can replace document.body with another element if needed)
    document.body.appendChild(parentDiv);
  } else if (!state) {
    const parentDiv = document.querySelector('.loading_overlay'); // Replace with the actual selector if needed
    // Remove the parentDiv from the document
    parentDiv.remove();
  }
};
