function Payments(order, payAmount, paymentMethod) {
  const userId = document.cookie
    .split('; ')
    .find((row) => row.startsWith('user='))
    ?.split('=')[1];

  const paymentType = Number(order.balance) - Number(payAmount) <= 0 ? 'Full Payment' : 'Deposit';

  let caPaymentOption = '';
  if (order.status === 'payment_failed') {
    caPaymentOption = 'order';
  } else if (
    order.status === 'deposit'
    || order.status === 'awaiting_supplier_conf'
    || order.status === 'action_required'
  ) {
    caPaymentOption = 'deposit_installment';
  } else if (order.status === 'quote') {
    caPaymentOption = 'quote';
  }

  let bookingType = 'ticket_order';

  order.order_tickets.forEach((ticket) => {
    const productCategory = Object.values(ticket.product_categories);
    /* eslint-disable-next-line no-plusplus */
    for (let i = 0; i < productCategory.length; i++) {
      if (
        productCategory[i].indexOf('Accommodation') !== -1
        || productCategory[i].includes('Accommodation')
      ) {
        bookingType = 'hotel_order';
        break;
      }
    }
  });

  const event = {
    event: 'caPurchase',
    caPurchase: {
      actionField: {
        caPaymentOption,
        id: order.id,
        userId,
        email: order.email,
        firstName: order.billing_address_fname,
        lastName: order.billing_address_lname,
        revenue: order.total,
        shipping:
          order.order_charges && order.order_charges.length > 0
            ? order.order_charges
              .filter((charge) => charge.type === 'shipping')
              .map((charge) => charge.price)[0]
            : null,
        paymentType,
        paymentMethod,
        payAmount,
        remainingBalance: String(Number(order.balance) - Number(payAmount)),
        shippingType:
          order.order_charges && order.order_charges.length > 0
            ? order.order_charges
              .filter((charge) => charge.type === 'shipping')
              .map((charge) => charge.title)[0]
            : null,
        bookingType,
        coupon:
          order.order_discounts && order.order_discounts.length > 0
            ? order.order_discounts[0].code
            : null,
        currency: order.currency,
        departureDate: order.departure_date,
      },
      products: order.order_tickets && order.order_tickets.length > 0 && order.order_tickets.map((ticket) => ({
        name: bookingType === 'hotel_order' && ticket.attractions.length > 0 ? ticket.attractions[0].title : ticket.product_title,
        id: ticket.product_id,
        nid: ticket.product_id,
        price: ticket.price,
        category: (ticket.destinations && ticket.destinations.length > 0) ? ticket.destinations[0].title : null,
        brand: (ticket.attractions && ticket.attractions.length > 0) ? ticket.attractions[0].title : null,
        variant: bookingType === 'hotel_order' ? ticket.product_title : ticket.title,
        sku: ticket.sku,
        quantity: ticket.qty,
      })),
    },
  };

  window.dataLayer.push(event);
}

export default Payments;
