import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import GooglePayButton from '@google-pay/button-react';
import googlePay from './GooglePay';
import styles from './GooglePay.module.scss';
import { getEnv, getLang } from '../../../../utils/OrderUtils';
import PaymentContext from '../../../../context/PaymentContext';
import { CheckoutDotComAPISettings } from '../../../../api/CheckoutDotComAPI';
import { domainLabel, getCountryCode } from '../../../../utils/ExternalPaymentsUtils';
import Payments from '../../../Analytics/Payments/Payments';
import Checkboxes from '../Checkboxes/Checkboxes';

function GooglePayTab({ order, payAmount }) {
  const { t } = useTranslation('common');

  const paymentCtx = useContext(PaymentContext);
  const { paymentData } = paymentCtx;

  const [checkoutDotComPublicKey, setCheckoutDotComPublicKey] = useState('');
  const [redirectUrl, setRedirectUrl] = useState('');
  const countryCode = getCountryCode();
  const env = getEnv();

  useEffect(() => {
    CheckoutDotComAPISettings()
      .then((result) => {
        setCheckoutDotComPublicKey(result.public_key);
      });
  }, [checkoutDotComPublicKey]);

  const amountLeftToPay = String(Number(order.balance) - Number(payAmount));

  // Payment success handler.
  const successPaymentHandler = () => {
    Payments(order, payAmount, 'google-pay');
    paymentData('payment-success', amountLeftToPay);
    setRedirectUrl('');
  };

  // Payment failure handler.
  const failedPaymentHandler = () => {
    paymentData('payment-failed', payAmount);
    setRedirectUrl('');
  };

  window.top.checkoutDotComSuccess = successPaymentHandler;

  window.top.checkoutDotComFailure = failedPaymentHandler;

  const [confirmQuoteChecked, setConfirmQuoteChecked] = useState(false);
  const [confirmTermsChecked, setConfirmTermsChecked] = useState(order && order.agree_terms === '1');
  const [receiveOffersChecked, setReceiveOffersChecked] = useState(order && order.agree_marketing === '1');

  const handleConfirmQuoteChange = (checked) => {
    setConfirmQuoteChecked(checked);
  };

  const handleConfirmTermsChange = (checked) => {
    setConfirmTermsChecked(checked);
  };

  const handleReceiveOffersChange = (checked) => {
    setReceiveOffersChecked(checked);
  };

  return (
    <div className={styles.googlepay}>
      <p className={styles.googleInfo}>{t('Google_pay_info')}</p>
      {['quote', 'in_checkout', 'payment_failed'].includes(order && order.status) && (
        <Checkboxes
          order={order}
          onConfirmQuoteChange={handleConfirmQuoteChange}
          onConfirmTermsChange={handleConfirmTermsChange}
          onReceiveOffersChange={handleReceiveOffersChange}
          confirmQuoteChecked={confirmQuoteChecked}
          confirmTermsChecked={confirmTermsChecked}
          receiveOffersChecked={receiveOffersChecked}
        />
      )}
      {redirectUrl !== '' ? (
        <iframe title="checkout" className={styles.iframe3DS} src={redirectUrl} />)
        : (
          <div className={
            (order.status === 'quote' && !confirmQuoteChecked)
              || (['quote', 'in_checkout', 'payment_failed'].includes(order.status) && !confirmTermsChecked)
              ? styles.disabledButton
              : styles.googleButton
          }
          >
            <GooglePayButton
              environment={env === 'production' ? 'PRODUCTION' : 'TEST'}
              paymentRequest={{
                apiVersion: 2,
                apiVersionMinor: 0,
                allowedPaymentMethods: [
                  {
                    type: 'CARD',
                    parameters: {
                      allowedAuthMethods: ['PAN_ONLY', 'CRYPTOGRAM_3DS'],
                      allowedCardNetworks: ['VISA', 'AMEX', 'MASTERCARD'],
                    },
                    tokenizationSpecification: {
                      type: 'PAYMENT_GATEWAY',
                      parameters: {
                        gateway: 'checkoutltd',
                        gatewayMerchantId: checkoutDotComPublicKey,
                      },
                    },
                  },
                ],
                merchantInfo: {
                  merchantId: env === 'production' ? 'BCR2DN4TZLD3TVLM' : 'merchantId',
                  merchantName: domainLabel().externalPaymentLabel,
                },
                transactionInfo: {
                  totalPriceStatus: 'FINAL',
                  totalPrice: payAmount,
                  currencyCode: order.currency,
                  countryCode,
                },
              }}
              buttonType="pay"
              onLoadPaymentData={(paymentRequest) => {
                googlePay(order, paymentRequest, payAmount, countryCode, setRedirectUrl, paymentData);
              }}
            />
          </div>
        )}

    </div>
  );
}

export default GooglePayTab;
