import { useState } from 'react';
import Modal from 'react-modal';
import { useTranslation } from 'react-i18next';
import { getTermsAndPolicyConfig } from '../../../../config/config';
import styles from './Checkboxes.module.scss';
import GetTermsAndPrivacy from '../../../../api/TermsAndPolicy';
import CheckboxesDataLayer from '../../../Analytics/Checkboxes/Checkboxes';

Modal.setAppElement();

function Checkboxes(props) {
  const { t } = useTranslation('common');
  const {
    order,
    onConfirmQuoteChange,
    onConfirmTermsChange,
    onReceiveOffersChange,
    confirmQuoteChecked,
    confirmTermsChecked,
    receiveOffersChecked,
  } = props;

  const termsAndPolicyCofig = getTermsAndPolicyConfig();

  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState('');
  const [modalTitle, setModalTitle] = useState('');

  const handleConfirmQuoteChange = (e) => {
    CheckboxesDataLayer('confirmQuoteDetails', !confirmQuoteChecked);
    onConfirmQuoteChange(e.target.checked);
  };

  const handleReceiveOffersChange = (e) => {
    CheckboxesDataLayer('agreeToReceiveOffers', !receiveOffersChecked);
    onReceiveOffersChange(e.target.checked);
  };

  const handleTermsChange = (e) => {
    CheckboxesDataLayer('agreeTermsClick', !confirmTermsChecked);
    onConfirmTermsChange(e.target.checked);
  };

  const handleTermsClick = (e) => {
    e.preventDefault();
    setModalTitle(t('booking-conditions'));
    setModalContent(termsAndPolicyCofig.termsLink);
    setShowModal(!showModal);
  };

  const handlePrivacyClick = (e) => {
    e.preventDefault();
    setModalTitle(t('privacy-policy'));
    setModalContent(termsAndPolicyCofig.policyLink);
    setShowModal(!showModal);
  };

  const handleModalClose = (e) => {
    e.preventDefault();
    setModalTitle('');
    setModalContent('');
    setShowModal(!showModal);
  };

  return (
    <>
      {order.status === 'quote' ? (
        <div>
          <div>
            <input value="test" type="checkbox" onChange={handleConfirmQuoteChange} />
            {t('confirm-quote-details')}
            <span title="Required" className={styles.required}> *</span>
          </div>
        </div>
      ) : null}
      <div>
        <input value="test" type="checkbox" onChange={handleReceiveOffersChange} checked={receiveOffersChecked} />
        {t('i-would-like-to-receive-holiday-updates-offers-and')}
      </div>
      <div>
        <input value="test" type="checkbox" onChange={handleTermsChange} checked={confirmTermsChecked} />
        {t('i-agree-with-the')}
        {' '}
        <a href="#modal" onClick={handleTermsClick}>
          {t('booking-conditions')}
        </a>
        {' '}
        {t('and')}
        {' '}
        {t('the')}
        {' '}
        <a href="#modal" onClick={handlePrivacyClick}>
          {t('privacy-policy')}
        </a>
        <span title="Required" className={styles.required}> *</span>
      </div>
      <Modal
        isOpen={showModal}
        contentLabel={modalTitle}
        onRequestClose={handleTermsClick}
      >
        <h2>{modalTitle}</h2>
        <div className={styles.leftText}>
          <GetTermsAndPrivacy id={modalContent} />
        </div>
        <button className={styles.close} type="button" onClick={handleModalClose}>{t('close')}</button>
      </Modal>
    </>
  );
}

export default Checkboxes;
