/* eslint-disable no-constant-condition */
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
// import { useQuery } from 'react-query';
import '../translations/i18n';
import API from '../api/phx';
import { getAppConfig } from '../config/config';

const config = getAppConfig();

// Return the formatted price.
export const formatPrice = (price) => {
  price = Number(price); /* eslint no-param-reassign: 0 */
  const FormattedPrice = typeof price === 'number' ? price.toFixed(2) : price;
  return FormattedPrice;
};

// Return the currency code for the given currency string.
export const getCurrencyCode = (currency) => {
  let currencyCode;
  switch (currency) {
    case 'GBP':
      currencyCode = '&pound;';
      break;
    case 'USD':
      currencyCode = '&#36;';
      break;
    case 'EUR':
      currencyCode = '&euro;';
      break;
    case 'AUD':
      currencyCode = '&#36;';
      break;
    default:
      currencyCode = '';
  }
  return currencyCode;
};

// Get enviroment.
export function getEnv() {
  if (typeof window === 'undefined') return null;
  if (window.location.host.includes('.test')) return 'dev';
  if (window.location.host.includes('localhost')) return 'dev';
  if (window.location.host.includes('atdtravel.com')) return 'staging';
  return 'production';
}

export const getAmount = (amount) => {
  let paymentAmount = amount.toString();
  if (paymentAmount.match(/\./)) paymentAmount = paymentAmount.replace(/\./, ''); // "99.99" --> "9999"
  else paymentAmount = paymentAmount.concat('00'); // "100" --> "10000"
  return paymentAmount;
};

export const getOrderTickets = (order, type) => {
  if (order.order_tickets) {
    const ticketsArray = [];
    let bookings = [];
    let returnsConfirmed = [];

    returnsConfirmed = getReturns(order.order_tickets);

    const tickets = order.order_tickets.forEach((ticket) => {
      let isVoucherBookng = false;
      if (type === 'post') {
        let bookingCount = ticket.booking && ticket.booking.length;
        if (bookingCount > 0) {
          ticket.booking.forEach((ticketBbooking) => {
            if (ticketBbooking.dispatch_method === 'evoucher') {
              bookingCount -= 1;
            }
          });
          if (bookingCount === 0) {
            isVoucherBookng = true;
          }
        }
      }

      if (ticket.booking && ticket.booking.length !== 0) {
        const bookingQty = ticket.booking.length;
        if (bookingQty > 1) {
          ticket.booking.forEach((booking) => {
            if (booking.dispatch_method === 'post' && booking.cost > 0) {
              let returns = 0;
              if (
                Array.isArray(returnsConfirmed[booking.order_ticket_id])
                && returnsConfirmed[booking.order_ticket_id].returns_confirmed
                  .length !== 0
              ) {
                returns = returnsConfirmed[booking.order_ticket_id].returns_confirmed;
              }
              booking.qty = ticket.qty;
              booking.returnsConfirmed = returns;
              bookings = {
                [ticket.order_ticket_id]: [booking],
              };
            }
          });
        }
      }

      if (ticket.qty > 0 && isVoucherBookng === false) {
        let date = '';
        let checkoutDate = '';
        let time = '';
        const ticketNumbers = [];
        const attributesArray = [];
        let firstNames = '';
        let lastNames = '';
        let leadFirstName = '';
        let leadLastName = '';
        let names = '';
        let weights = '';
        ticket.attributes.forEach((attributes) => {
          const attributesIdArray = attributes.attribute_id.split('-');
          const attributeId = attributesIdArray[2];

          if (
            attributeId === '10'
            && attributes.values.length !== 0
            && attributes.backend_only === '0'
          ) {
            time = attributes.values[0];
          } else if (
            attributeId === '5'
            && attributes.values.length !== 0
            && attributes.backend_only === '0'
          ) {
            if (ticket.booking.length === 1) {
              date = attributes.values[0];
            } else {
              attributesArray.push({
                [attributes.title.includes(': ')
                  ? attributes.title.split(': ')[1]
                  : attributes.title]: attributes.values[0],
              });
            }
          } else if (
            attributes.backend_only === '0'
            && attributes.required === '1'
            && attributes.type !== 'hidden'
          ) {
            attributes[ticket.order_ticket_id] = attributesIdArray[1];
            if (attributes.title.toLowerCase().includes('name')) {
              if (
                attributes.values
                  .map((value) => value.toLowerCase())
                  .indexOf('x') > -1
                || attributes.values
                  .map((value) => value.toLowerCase())
                  .indexOf('xx') > -1
                || attributes.values.indexOf('Names required before any payment')
                  > -1
                || !attributes.values
              ) {
                const attiburesArrayKeys = attributesArray
                  .map((att) => Object.keys(att))
                  .flat();
                if (
                  attributes.title.includes('Lead')
                  && !attiburesArrayKeys.includes('Lead Passenger Name')
                ) {
                  names = 'Required';
                  attributesArray.unshift({
                    'Lead Passenger Name': 'Required',
                  });
                } else if (
                  !attributes.title.includes('Lead')
                  && !attiburesArrayKeys.includes('Passenger Name(s)')
                ) {
                  names = 'Required';
                  attributesArray.push({
                    'Passenger Name(s)': 'Required',
                  });
                }
              } else if (
                attributes.title.includes('Lead Passenger First name')
              ) {
                leadFirstName = attributes.values;
              } else if (
                attributes.title.includes('Lead Passenger Last name')
              ) {
                leadLastName = attributes.values;
              } else if (
                attributes.title.includes('First name')
                && !attributes.title.includes('Lead')
              ) {
                firstNames = attributes.values;
              } else if (
                attributes.title.includes('Last name')
                && !attributes.title.includes('Lead')
              ) {
                lastNames = attributes.values;
              } else if (!attributes.values.includes('0')) {
                names = attributes.values;
                const attributeKey = attributes.title.includes('Lead')
                  ? 'Lead Passenger Name'
                  : 'Passenger Name(s)';
                const attiburesArrayKeys = attributesArray
                  .map((att) => Object.keys(att))
                  .flat();
                if (
                  !attiburesArrayKeys.includes(attributeKey)
                  && attributeKey === 'Passenger Name(s)'
                ) {
                  attributesArray.push({
                    [attributeKey]:
                      attributes.values.length === 1
                        ? formatName(attributes.values[0])
                        : formatName(attributes.values.join(', ')),
                  });
                } else if (
                  !attiburesArrayKeys.includes(attributeKey)
                  && attributeKey === 'Lead Passenger Name'
                ) {
                  attributesArray.unshift({
                    [attributeKey]:
                      attributes.values.length === 1
                        ? formatName(attributes.values[0])
                        : formatName(attributes.values.join(', ')),
                  });
                }
              }
              if (
                firstNames
                && lastNames
                && firstNames.length === lastNames.length
                && (attributes.title.includes('First name')
                  || attributes.title.includes('Last name'))
              ) {
                const fullNames = firstNames.map((firstName, i) => formatName(`${firstName} ${lastNames[i]}`));
                const attributeKey = 'Passenger Name(s)';
                const attiburesArrayKeys = attributesArray
                  .map((att) => Object.keys(att))
                  .flat();
                if (!attiburesArrayKeys.includes(attributeKey)) {
                  attributesArray.push({
                    [attributeKey]: fullNames.join(', '),
                  });
                }
              }
              if (
                leadFirstName
                && leadLastName
                && leadFirstName.length === leadLastName.length
              ) {
                const attributeKey = 'Lead Passenger Name';
                const attiburesArrayKeys = attributesArray
                  .map((att) => Object.keys(att))
                  .flat();
                const fullName = `${leadFirstName} ${leadLastName}`;
                if (!attiburesArrayKeys.includes(attributeKey)) {
                  attributesArray.unshift({
                    [attributeKey]: fullName,
                  });
                }
              }
              if (weights && names) {
                const weightsWithNames = weights.map((weight, i) => (weight.toLowerCase() === 'x'
                  || weight.toLowerCase() === 'xx'
                  || names === 'Required'
                  ? 'Required'
                  : `${weight} (${formatName(names[i])})`));
                attributesArray.push({
                  'Passenger weight in lbs':
                    weightsWithNames.indexOf('Required') > -1
                      ? 'Required'
                      : weightsWithNames.join(', '),
                });
              }
            } else if (attributeId === '17') {
              weights = attributes.values;
              if (weights && names) {
                const weightsWithNames = weights.map((weight, i) => (weight.toLowerCase() === 'x'
                  || weight.toLowerCase() === 'xx'
                  || names === 'Required'
                  ? 'Required'
                  : `${weight} (${formatName(names[i])})`));
                attributesArray.push({
                  [attributes.title]:
                    weightsWithNames.indexOf('Required') > -1
                      ? 'Required'
                      : weightsWithNames.join(', '),
                });
              }
            } else if (attributeId === '50') {
              if (
                attributes.values
                  .map((value) => value.toLowerCase())
                  .indexOf('x') > -1
                || attributes.values
                  .map((value) => value.toLowerCase())
                  .indexOf('xx') > -1
                || attributes.values.indexOf('Names required before any payment')
                  > -1
                || !attributes.values
              ) {
                attributesArray.push({
                  [attributes.title]: 'Required',
                });
              } else {
                let adults = '';
                if (attributes.values[0].includes('Children')) {
                  adults = attributes.values[0]
                    .split('Children')[0]
                    .split('\n');
                } else if (attributes.values[0].includes('Infants')) {
                  adults = attributes.values[0].split('Infants')[0].split('\n');
                } else {
                  adults = attributes.values[0].split('\n');
                }
                adults.shift();
                adults = adults
                  .filter((n) => n)
                  .map((n) => formatName(n.substring(3)));
                attributesArray.push({
                  Adults: (adults[0].toLowerCase() === 'x' || adults[0].toLowerCase() === 'xx') ? 'Required' : adults.join(', '),
                });
                let children = attributes.values[0].includes('Children')
                  ? attributes.values[0].split('Children')[1]
                  : null;
                if (children) {
                  if (children.includes('Infants')) {
                    children = children.split('Infants')[0];
                  }
                  children = children.split('\n').filter((item) => item && item !== ':');
                  if (children.length > 0 && children.some((str) => str.trim().length > 0)) {
                    children = children
                      .filter((n) => n)
                      .map((n) => n.substring(3));
                    children = children.map((n) => {
                      const age = Math.abs(
                        new Date(
                          (date
                            ? new Date(date.split('/').reverse().join('-'))
                            : new Date(order.departure_date))
                            - new Date(n.split('(')[1].slice(0, -1)).getTime(),
                        ).getUTCFullYear() - 1970,
                      );
                      return `${formatName(n.split(' (')[0])} (${age})`;
                    });
                    attributesArray.push({
                      Children: children[0].includes('X')
                        ? 'Required'
                        : children.join(', '),
                    });
                  }
                }
                let infants = attributes.values[0].includes('Infants')
                  ? attributes.values[0].split('Infants')[1].split('\n').filter((item) => item && item !== ':')
                  : null;
                if (infants) {
                  if (infants.length > 0 && infants.some((str) => str.trim().length > 0)) {
                    infants = infants
                      .filter((n) => n)
                      .map((n) => n.substring(3));
                    infants = infants.map((n) => {
                      const age = Math.abs(
                        new Date(
                          (date
                            ? new Date(date.split('/').reverse().join('-'))
                            : new Date(order.departure_date))
                            - new Date(n.split('(')[1].slice(0, -1)).getTime(),
                        ).getUTCFullYear() - 1970,
                      );
                      return `${formatName(n.split(' (')[0])} (${age === 0 ? 'under 1' : age})`;
                    });
                    attributesArray.push({
                      Infants: infants[0].includes('X')
                        ? 'Required'
                        : infants.join(', '),
                    });
                  }
                }
              }
            } else if (
              attributes.values
                .map((value) => value.toLowerCase())
                .indexOf('x') > -1
              || attributes.values
                .map((value) => value.toLowerCase())
                .indexOf('xx') > -1
              || attributes.values.indexOf('Names required before any payment')
                > -1
              || !attributes.values
            ) {
              attributesArray.push({
                [attributes.title]: 'Required',
              });
            } else if (attributeId === '42' && attributes.values.length !== 0) {
              checkoutDate = attributes.values[0];
            } else if (attributeId === '112') {
              ticketNumbers[ticket.order_ticket_id] = {
                [attributes[ticket.order_ticket_id]]: attributes.values,
              };
            } else if (
              !attributes.values.includes('0')
              && attributeId !== '48'
              && attributeId !== '49'
              && attributeId !== '87'
            ) {
              if (attributes.title.toLowerCase().includes('name')) {
                attributesArray.push({
                  [attributes.title]:
                    attributes.values.length === 1
                      ? formatName(attributes.values[0])
                      : formatName(attributes.values.join(', ')),
                });
              } else {
                attributesArray.push({
                  [attributes.title]:
                    attributes.values.length === 1
                      ? attributes.values[0]
                      : attributes.values.join(', '),
                });
              }
            }
          } else if (
            attributeId === '5'
            && attributes.values.length !== 0
            && ticket.booking.length > 1
          ) {
            attributesArray.push({
              [attributes.title.includes(': ')
                ? attributes.title.split(': ')[1]
                : attributes.title]: attributes.values[0],
            });
          }
        });
        let title = ticket.product_title !== '' ? ticket.product_title : ticket.title;
        let isHotelRoom = false;
        Object.values(ticket.product_categories).forEach(
          (productCategories) => {
            if (productCategories.indexOf('Accommodation') !== -1) {
              isHotelRoom = true;
            }
          },
        );

        if (
          isHotelRoom === true
          && ticket.attractions[0]
          && ticket.attractions[0].title !== ''
          && title.indexOf(ticket.attractions[0].title) === -1
        ) {
          title = `${ticket.attractions[0].title} - ${title}`;
        }

        let bookingTicketId = [];
        if (
          bookings
          && bookings.length !== 0
          && bookings[ticket.order_ticket_id]
          && bookings[ticket.order_ticket_id].length !== 0
        ) {
          bookingTicketId = bookings[ticket.order_ticket_id];
        }
        let returnsConfirmedOrderId = 0;
        if (
          Array.isArray(returnsConfirmed[ticket.order_ticket_id])
          && returnsConfirmed[ticket.order_ticket_id].returns_confirmed.length
            !== 0
        ) {
          returnsConfirmedOrderId = returnsConfirmed[ticket.order_ticket_id].returns_confirmed;
        }
        let ticketNumbersOrderId = [];
        if (
          ticketNumbers.length !== 0
          && ticketNumbers[ticket.order_ticket_id].length !== 0
        ) {
          ticketNumbersOrderId = ticketNumbers[ticket.order_ticket_id];
        }

        // Manually adding SW and/or UO start date for Freedom Ticket, FlexiTicket and Ultimate Ticket
        if (
          ticket.product_id === '328539'
          || ticket.product_id === '328613'
          || ticket.product_id === '328580'
          || ticket.product_id === '293913'
          || ticket.product_id === '338242'
          || ticket.product_id === '338244'
          || ticket.product_id === '338254'
          || ticket.product_id === '338253'
          || ticket.product_id === '338277'
          || ticket.product_id === '338266'
        ) {
          attributesArray.push({
            'SeaWorld Ticket Start Date':
              order.departure_date
                .split('-')
                .reverse()
                .join('-')
                .replaceAll('-', '/'),
          });
        }

        ticketsArray[ticket.order_ticket_id] = [
          {
            order_ticket_id: ticket.order_ticket_id,
            product_id: ticket.product_id,
            ticket_id: ticket.ticket_id,
            img: ticket.img === '' ? '' : ticket.img,
            ticket_title: title,
            date,
            checkout_date: checkoutDate,
            time,
            combo: ticket.booking && ticket.booking.length > 1,
            bookings: bookingTicketId,
            quantity: ticket.qty,
            returns_confirmed: returnsConfirmedOrderId,
            price: ticket.price,
            price_option_id:
              ticket.price_option_id.length === 0 ? '' : ticket.price_option_id,
            price_option_title:
              ticket.price_option_title.length === 0
                ? ''
                : ticket.price_option_title,
            type: ticket.type_ticket,
            description: ticket.type_description,
            year: ticket.year,
            ticket_numbers: ticketNumbersOrderId,
            currency: order.currency,
            attributes: attributesArray,
            ticket_subtitle: ticket.title,
            order_status: order.status,
          },
        ];
      }
    });

    const productsList = getProducts(ticketsArray).filter((product) => {
      if (product.type !== 'product_amendment') {
        return true;
      }
      return false;
    });
    return productsList;
  }
  return null;
};

export const getEditableAttributes = (order) => {
  if (!order.order_tickets) {
    return [];
  }

  const ticketsArray = [];

  const blackFridayNids = ['275879', '321273', '321270', '321271', '254384', '296574', '321261', '321262', '234084', '296586', '321267', '321268', '321628', '321626', '321629', '321627', '254503', '321618', '281016', '321623', '254504', '321624', '281017', '321625', '341595', '341601', '341606'];

  order.order_tickets.forEach((ticket) => {
    const attributesArray = ticket.attributes.filter((attribute) => attribute.updatable_by_customer === '1');
    const ticketTitle = blackFridayNids.includes(ticket.product_id) && ticket.product_title.includes(' - ') ? ticket.product_title.split(' - ')[0] : ticket.product_title;
    const time = ticket.attributes.filter((attribute) => attribute.attribute_id.split('-')[2] === '10');
    const date = ticket.attributes.filter((attribute) => attribute.attribute_id.split('-')[2] === '5');

    if (attributesArray.length > 0) {
      ticketsArray.push({
        [ticketTitle]: attributesArray.map((attribute) => {
          const ticketEntry = {
            ...attribute,
            ticket_type: ticket.type,
            ticket_type_description: ticket.type_description,
          };

          // Conditionally add date if it exists
          if (date && date[0] && date[0].values && date[0].values[0]) {
            ticketEntry.date = date[0].values[0];
          }

          // Conditionally add time if it exists
          if (time && time[0] && time[0].values && time[0].values[0]) {
            ticketEntry.time = time[0].values[0];
          }

          return ticketEntry;
        }),
      });
    }
  });

  return ticketsArray;
};

const getReturns = (orderTickets) => {
  const returnsConfirmed = [];
  orderTickets.forEach((orderTicket) => {
    if (Array.isArray(orderTicket.returns.tickets)) {
      orderTicket.returns.tickets.forEach((ticket) => {
        returnsConfirmed[ticket.returns_order_ticket_id] = ticket.returns_confirmed;
      });
    } else {
      orderTicket.returns.forEach((returnTickets) => {
        returnTickets.forEach((returnTicket) => {
          returnsConfirmed[returnTicket.returns_order_ticket_id] = returnTicket;
        });
      });
    }
  });

  return returnsConfirmed;
};

const getProducts = (ticketsArray) => {
  const products = [];
  const ticketsByProduct = [];
  if (ticketsArray.length !== 0) {
    ticketsArray.forEach((tickets) => {
      tickets.forEach((ticket) => {
        const key = ticket.order_ticket_id;
        ticketsByProduct[key] = [ticket];
      });
    });

    const bookingTickets = [];
    const bookingTicketNumbers = [];

    ticketsByProduct.forEach((tickets, key) => {
      // Check if the product exists
      const productExists = Object.hasOwn(products, key);
      if (!productExists) {
        products[key] = {
          order_ticket_id: tickets[0].order_ticket_id,
          title: tickets[0].ticket_title,
          product_id: tickets[0].product_id,
          ticket_id: tickets[0].ticket_id,
          img: tickets[0].img,
          date: tickets[0].date,
          checkout_date: tickets[0].checkout_date,
          time: tickets[0].time,
          ticket_numbers: [],
          tickets: [],
          price: 0,
          type: tickets[0].type,
          attributes: tickets[0].attributes,
          quantity: tickets[0].quantity,
          subtitle: tickets[0].ticket_subtitle,
          combo: tickets[0].combo,
          order_status: tickets[0].order_status,
        };
      }

      // Add ticket details to products
      tickets.forEach((ticket) => {
        let ticketTitle = '';
        products[key].order_ticket_id = ticket.order_ticket_id;
        products[key].price += ticket.quantity * ticket.price;
        products[key].currency = ticket.currency;

        // Add ticket titles and quantities
        let parts = [];
        const ticketFieldsArray = [
          'type',
          'description',
          'year',
          'price_option_title',
        ];
        ticketFieldsArray.forEach((field) => {
          if (ticket[field] !== '') {
            let part = ticket[field];
            if (field === 'price_option_title') {
              part = `(${part})`;
            }
            parts = [...parts, part];
          }
        });

        // Combo tickets
        let quantity = 0;
        if (ticket.bookings.length > 0) {
          ticket.bookings.forEach((bookings) => {
            quantity = bookings.qty - bookings.returnsConfirmed;
            products[key].price = bookings.returns_confirmed * ticket.price;
            if (parts.length > 0) {
              ticketTitle = `${quantity} x ${bookings.title} ${parts.join()}`;
              bookingTickets[key] = [
                {
                  [bookings.product_id]: [ticketTitle],
                },
              ];
              let ticketNumber = [];
              if (
                ticket.ticket_numbers.length > 0
                && ticket.ticket_numbers[bookings.order_ticket_id].length > 0
              ) {
                ticketNumber = ticket.ticket_numbers[bookings.order_ticket_id];
                bookingTicketNumbers[key] = [
                  {
                    [bookings.product_id]: [ticketNumber],
                  },
                ];
              }
            }
          });
        } else {
          if (parts.length > 0) {
            ticketTitle = `${ticket.quantity} x ${parts.join()}`;
          }
          let ticketNumber = [];

          if (
            ticket.ticket_numbers.length > 0
            && ticket.ticket_numbers[ticket.order_ticket_id].length > 0
          ) {
            ticketNumber = [ticket.ticket_numbers[ticket.order_ticket_id]];
          }

          products[key].tickets = [
            {
              ticketTitle,
            },
          ];
          if (ticketNumber.length > 0) {
            products[key].ticket_numbers = [
              {
                ticketNumber,
              },
            ];
          }
        }

        if (Object.values(bookingTickets).length > 0) {
          Object.values(bookingTickets).forEach((bookingTicket) => {
            Object.values(bookingTicket).forEach((product) => {
              Object.keys(product).forEach((value) => {
                products[key].tickets = [
                  {
                    value,
                  },
                ];
              });
            });
          });
        }

        if (bookingTicketNumbers.length > 0) {
          bookingTicketNumbers.forEach((bookingTicketNumber) => {
            bookingTicketNumber.forEach((bookingTicketNum) => {
              bookingTicketNum.forEach((value) => {
                products[key].ticket_numbers = [
                  {
                    value,
                  },
                ];
              });
            });
          });
        }
      });
    });
  }

  return products;
};

// Return the language code.
export const getLang = () => {
  let lang = 'en';
  if (typeof window.drupalSettings !== 'undefined') {
    lang = window.drupalSettings.path.currentLanguage;
  }
  return lang;
};

export const getVouchers = (order) => {
  if (order.order_tickets) {
    const voucher = [];
    let returnsConfirmed = [];

    returnsConfirmed = getReturns(order.order_tickets);

    const tickets = order.order_tickets.forEach((ticket) => {
      let isHotelVoucher = false;
      Object.values(ticket.product_categories).forEach((productCategories) => {
        if (productCategories.indexOf('Accommodation') !== -1) {
          isHotelVoucher = true;
        }
      });

      if (!isHotelVoucher) {
        // Check if the ticket is a voucher.
        let isVoucherBooking = false;
        const combo = ticket.booking && ticket.booking.length > 1;
        if (ticket.booking) {
          ticket.booking.forEach((booking) => {
            if (booking.dispatch_method === 'evoucher') {
              isVoucherBooking = true;
            }
          });
        }

        // If all quantity of the order product is returned, don't show it.
        if (isVoucherBooking && ticket.qty > 0 && ticket.vouchers) {
          let date = '';
          let time = '';
          const ticketNumbers = [];
          if (ticket.attributes.length > 0) {
            ticket.attributes.forEach((attributes) => {
              const attributesArray = attributes.attribute_id.split('-');
              const attributeId = attributesArray[2];
              const attributeOrderProductId = attributesArray[1];
              if (attributes.backend_only === '0') {
                if (attributeId === '5' && attributes.values.length > 0) {
                  date = attributes.values[0];
                } else if (
                  attributeId === '10'
                  && attributes.values.length > 0
                ) {
                  time = attributes.values[0];
                } else if (attributeId === '112') {
                  ticketNumbers[attributeOrderProductId] = attributes.values;
                }
              }
            });
          }
          ticket.vouchers.forEach((vouchers) => {
            if (combo) {
              ticket.attributes.forEach((attributes) => {
                const attributesArray = attributes.attribute_id.split('-');
                const attributeId = attributesArray[2];
                const parkName = vouchers.title.split(' ')[0];
                // Manually adding SW and/or UO start date for Freedom Ticket, FlexiTicket and Ultimate Ticket
                if (
                  ticket.product_id === '328539'
                  || ticket.product_id === '328613'
                  || ticket.product_id === '328580'
                  || ticket.product_id === '293913'
                ) {
                  if (parkName === 'SeaWorld') {
                    date = order.departure_date
                      .split('-')
                      .reverse()
                      .join('-')
                      .replaceAll('-', '/');
                  } else if (
                    attributeId === '5'
                    && attributes.title.includes(parkName)
                  ) {
                    date = attributes.values[0];
                  }
                } else if (
                  attributes.backend_only === '0'
                  && attributeId === '5'
                  && attributes.values.length > 0
                  && attributes.product_title.includes(parkName)
                ) {
                  date = attributes.values[0];
                }
              });
            }
            let quantity = ticket.qty;
            const voucherOrderTicketBookingId = vouchers.order_ticket_booking_id;
            if (returnsConfirmed[voucherOrderTicketBookingId] !== 0) {
              quantity -= returnsConfirmed[voucherOrderTicketBookingId];
            }

            const productAttribute = ticket.attributes.find(
              (attribute) => attribute.product_title
                && vouchers.title
                && attribute.product_title.includes(vouchers.title.split(' ')[0]),
            );

            let voucherArr = [];
            const voucherTicketNumbers = ticketNumbers[
              vouchers.order_ticket_booking_id
            ]
              ? ticketNumbers[vouchers.order_ticket_booking_id]
              : [];
            voucherArr = {
              voucher_id: vouchers.voucher_id,
              voucher_order_product_id: vouchers.order_ticket_booking_id,
              product_id: ticket.product_id ? ticket.product_id : '',
              ticket_id: ticket.ticket_id ? ticket.ticket_id : '',
              img: ticket.img ? ticket.img : '',
              product_title: ticket.product_title ? ticket.product_title : '',
              ticket_title: vouchers.title,
              date,
              time,
              combo,
              quantity,
              price: ticket.price ? ticket.price : '',
              type: ticket.type ? ticket.type : '',
              description: ticket.type_description
                ? ticket.type_description
                : '',
              vouchers: ticket.vouchers ? ticket.vouchers : '',
              ticket_numbers: voucherTicketNumbers,
              currency: order.currency,
              order_status: order.status,
              book_on_deposit: !!(
                ticket.booking[0].require_fully_paid_api === '0'
                && ticket.booking[0].booking_status
              ),
              booking_status:
                ticket.booking[0].booking_status === 'booked'
                  ? 'Confirmed'
                  : 'Pending',
              price_option_title: ticket.price_option_title
                ? ticket.price_option_title
                : '',
              destination:
                ticket.destinations && ticket.destinations.length > 0
                  ? ticket.destinations[0].title
                  : '',
              type_ticket: ticket.type_ticket,
              ticket_subtitle: productAttribute
                ? productAttribute.product_title
                : null,
            };

            if (vouchers.voucher_id in voucher) {
              voucher[vouchers.voucher_id].push(voucherArr);
            } else {
              voucher[vouchers.voucher_id] = [voucherArr];
            }
          });
        }
      }
    });
    const voucherTickets = getVouchersArray(voucher);
    return voucherTickets;
  }
  return null;
};

export const getVouchersArray = (orderTickets) => {
  const voucher = [];
  orderTickets.forEach((tickets) => {
    const voucherPath = [];
    let price = 0;
    const ticketList = [];
    const ticketNumbers = [];
    const voucherTickets = [];
    let orderVouchers = [];
    tickets.forEach((orderVoucher) => {
      if (orderVoucher.ticket_id in ticketList) {
        ticketList[orderVoucher.ticket_id] += orderVoucher.quantity;
      } else {
        ticketList[orderVoucher.ticket_id] = orderVoucher.quantity;
      }
      const ticketDesc = ` x ${orderVoucher.type} ${orderVoucher.description}${orderVoucher.price_option_title ? ` (${orderVoucher.price_option_title})` : ''}`;
      const comboDesc = ` x ${orderVoucher.ticket_title}`;

      const title = orderVoucher.product_title;
      price = orderVoucher.quantity * orderVoucher.price + price;
      ticketNumbers[orderVoucher.ticket_id] = orderVoucher.ticket_numbers;

      if (orderVoucher.combo) {
        voucherTickets[orderVoucher.ticket_id] = orderVoucher.ticket_id in ticketList
          ? ticketList[orderVoucher.ticket_id] + comboDesc
          : orderVoucher.quantity + comboDesc;
      } else {
        voucherTickets[orderVoucher.ticket_id] = orderVoucher.ticket_id in ticketList
          ? ticketList[orderVoucher.ticket_id] + ticketDesc
          : orderVoucher.quantity + ticketDesc;
      }
      let voucherArr = [];
      orderVoucher.vouchers.forEach((vouchers) => {
        if (orderVoucher.voucher_id === vouchers.voucher_id) {
          voucherArr = vouchers;
        }
      });

      const voucherId = voucherArr.voucher_id;
      const releaseDate = voucherArr.release_date;

      // Additional data
      const data = [];
      if (orderVoucher.product_id === '3211') {
        // It's a new york explorer pass, show additional guide.
        data.new_york_pass_guide = true;
      }
      if (orderVoucher.product_id === '4897') {
        // It's a new york pass, show additional guide.
        data.new_york_guide = true;
      }

      const $overrideMessage = useOverrideMessage(
        orderVoucher.product_id,
        voucherArr.status,
      );
      voucherArr.download_url.forEach((downloadUrl) => {
        if (!(downloadUrl in voucherPath)) {
          voucherPath[downloadUrl] = {
            label: `${orderVoucher.type} ${orderVoucher.description}`,
            url: appendGeoParam(downloadUrl, config.brand),
          };
        }
      });

      let statusTitle = '';
      switch (voucherArr.status) {
        case 'awaiting_upload':
        case 'awaiting_supplier_conf':
        case 'incomplete_actions':
        case 'awaiting_stock':
          statusTitle = 'We will email you once your tickets are available to download.';
          break;
        default: {
          const voucherStatus = voucherArr.status.replaceAll('_', ' ');
          statusTitle = voucherStatus.charAt(0).toUpperCase() + voucherStatus.slice(1);
          break;
        }
      }
      if ($overrideMessage) {
        statusTitle = $overrideMessage;
      }

      orderVouchers = {
        title,
        img: orderVoucher.img,
        date: orderVoucher.date,
        time: orderVoucher.time,
        tickets: voucherTickets,
        price,
        quantity: orderVoucher.quantity,
        vouchers: {
          status_title: statusTitle,
          status: voucherArr.status,
          voucher_id: voucherId,
          path: voucherPath,
          release_date: releaseDate,
        },
        data,
        ticket_numbers: ticketNumbers,
        currency: orderVoucher.currency,
        order_status: orderVoucher.order_status,
        type_ticket: orderVoucher.type_ticket,
        destination: orderVoucher.destination,
        combo: orderVoucher.combo,
        subtitle: orderVoucher.ticket_subtitle,
        book_on_deposit: orderVoucher.book_on_deposit,
        booking_status: orderVoucher.booking_status,
        product_id: orderVoucher.product_id,
      };
    });
    voucher.push(orderVouchers);
  });

  return voucher;
};

export const useOverrideMessage = (productId, status) => {
  let message = '';
  switch (productId) {
    case '233391':
      if (status !== 'ready_to_download') {
        message = 'Your Black Friday Offer ticket will be available to download from 15 March 2019';
      }
      break;
    default:
      message = '';
  }
  return message;
};

const getPerRoomTicketDining = async (orderTickets) => {
  // Get the hotel rules around whether tickets are sold per room or not.
  const orderTicket = Object.values(orderTickets);
  const promises = [];
  /* eslint-disable-next-line no-plusplus */
  for (let i = 0; i < orderTicket.length; i++) {
    const hotelCategories = getHotelCategory(orderTicket[i].product_categories);
    if (
      hotelCategories.hotelCategory
      && hotelCategories.hotelCategory === 'accommodation'
    ) {
      if (orderTicket[i].attractions.length > 0) {
        const hotelId = orderTicket[i].attractions[0].attraction_id;
        promises.push(getHotelData(hotelId));
      }
    }
  }

  const hotel = await Promise.all(promises);
  return hotel;
};

export const getHotelData = async (hotelId) => {
  const hotelApiPath = `/hotels/${hotelId}?fields=checkout_flow&cached=1&geo=${getLang()}`;
  const hotel = await API.get(hotelApiPath);
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(hotel.data);
    }, 1000);
  });
};

export const getHotelVouchers = async (order) => {
  if (order.order_tickets) {
    const vouchersArr = [];
    let voucherDownloadableCancellation = false;
    let bookingCanceled = false;

    let roomDining = false;
    let roomTickets = false;

    const roomTicketDine = await getPerRoomTicketDining(order.order_tickets);
    if (roomTicketDine.length > 0) {
      if (roomTicketDine[0].checkout_flow.dining.per_room > 0) {
        roomDining = true;
      }
      if (roomTicketDine[0].checkout_flow.tickets.per_room > 0) {
        roomTickets = true;
      }
    }

    const tickets = order.order_tickets.forEach((ticket) => {
      // 210867 is the nid of "Hotel Full Cancellation"
      if (ticket.ticket_id === 210867) {
        voucherDownloadableCancellation = parseInt(ticket.attributes[0].values[0], 10) === 1;
        bookingCanceled = true;
      }

      let isHotelVoucher = false;
      let hotelCategory = '';
      const hotels = getHotelCategory(ticket.product_categories);
      if (hotels) {
        isHotelVoucher = hotels.isHotel;
        hotelCategory = hotels.hotelCategory;
      }

      if (isHotelVoucher) {
        // Check if the ticket is a voucher.
        let isVoucherBooking = false;
        if (ticket.booking) {
          isVoucherBooking = checkVoucherBooking(ticket.booking);
        }
        const vouchArr = [];

        // If all quantity of the order product is returned, don't show it.
        if (
          isVoucherBooking
          && parseInt(ticket.qty, 10) > parseInt(ticket.qty_returned, 10)
          && ticket.vouchers.length > 0
        ) {
          let checkIn = '';
          let checkOut = '';
          let supplierConfirmationNumber = '';
          const dateTime = [];
          const occupancy = {
            qty: [],
            details: [],
          };

          switch (hotelCategory) {
            case 'accommodation':
              // If it is a hotel room, we need the occupancy details.
              if (ticket.attributes.length > 0) {
                ticket.attributes.forEach((attributes) => {
                  const attributesArray = attributes.attribute_id.split('-');
                  const attributeId = attributesArray[2];
                  const attributeOrderProductId = attributesArray[1];

                  // Get the check in date.
                  if (attributeId === '5' && attributes.values.length > 0) {
                    checkIn = attributes.values[0];
                    // Get the checkout date.
                  } else if (
                    attributeId === '42'
                    && attributes.values.length > 0
                  ) {
                    checkOut = attributes.values[0];
                    // Get the number of adults.
                  } else if (
                    attributeId === '48'
                    && attributes.values.length > 0
                  ) {
                    occupancy.qty.push({ adults: attributes.values[0] });
                    // Get the number of children.
                  } else if (
                    attributeId === '49'
                    && attributes.values.length > 0
                  ) {
                    occupancy.qty.push({ children: attributes.values[0] });
                    // Get the number of infants.
                  } else if (
                    attributeId === '87'
                    && attributes.values.length > 0
                  ) {
                    occupancy.qty.push({ infants: attributes.values[0] });
                    // Get the occupant details.
                  } else if (
                    attributeId === '50'
                    && attributes.values.length > 0
                  ) {
                    let occupants = attributes.values[0].trim();
                    occupants = occupants.replace('\r', '');
                    const occupantList = occupants.split('\n\n');
                    occupancy.details = getAccommodationOccupancy(
                      occupantList,
                      checkOut,
                    );
                    // For DLP (supplier id 133) we use the supplier order id and for all others we use the booking confirmation number
                  } else if (
                    attributeId === '111'
                    && attributes.values.length > 0
                    && ticket.booking[0].supplier_id === '133'
                  ) {
                    supplierConfirmationNumber = attributes.values[0];
                  } else if (
                    attributeId === '9'
                    && attributes.values.length > 0
                    && ticket.booking[0].supplier_id !== '133'
                  ) {
                    supplierConfirmationNumber = attributes.values[0];
                  }
                });
              }
              break;
            case 'accommodation tickets':
            case 'accommodation tickets (free sell permitted)':
              // Get the date / time information for the ticket.
              if (ticket.attributes.length > 0) {
                ticket.attributes.forEach((attributes) => {
                  const attributesArray = attributes.attribute_id.split('-');
                  const attributeId = attributesArray[2];

                  // Get the date for the ticket.
                  if (attributeId === '5' && attributes.values.length > 0) {
                    dateTime.startDate = attributes.values[0];
                  } else if (
                    attributeId === '42'
                    && attributes.values.length > 0
                  ) {
                    dateTime.endDate = attributes.values[0];
                  } else if (
                    attributeId === '10'
                    && attributes.values.length > 0
                  ) {
                    dateTime.time = attributes.values[0];
                  }
                });
              }
              break;
            default: {
              break;
            }
          }

          let hotelAddress = [];

          if (ticket.attractions.length > 0) {
            hotelAddress = {
              name: ticket.attractions[0].address_company,
              street1: ticket.attractions[0].address_street1,
              street2: ticket.attractions[0].address_street2,
              city: ticket.attractions[0].address_city,
              region: ticket.attractions[0].address_region,
              postcode: ticket.attractions[0].address_postal_code,
              country: ticket.attractions[0].address_country,
            };
          }

          let aid9 = '';
          if (ticket.attributes.length > 0) {
            ticket.attributes.forEach((attributes) => {
              const attributesArray = attributes.attribute_id.split('-');
              const attributeId = attributesArray[2];
              if (attributeId === '9' && attributes.values.length > 0) {
                aid9 = attributes.values[0];
                ticket.aid9 = aid9;
              }
            });
          }

          if (ticket.vouchers[0].voucher_id.length > 0) {
            const voucherId = ticket.vouchers[0].voucher_id;
            vouchArr[ticket.vouchers[0].voucher_id] = {
              orderTicketId: ticket.order_ticket_id,
              productId: ticket.product_id,
              ticketId: ticket.ticket_id,
              dateId: ticket.date_id ? ticket.date_id : 1,
              dateTime,
              img: ticket.img,
              attractionImg:
                ticket.attractions.length > 0 ? ticket.attractions[0].img : '',
              ticketTitle:
                ticket.product_title.length > 0 ? ticket.product_title : '',
              hotelTitle:
                ticket.attractions.length > 0
                  ? ticket.attractions[0].title
                  : '',
              checkIn,
              checkOut,
              occupancy,
              qty: parseInt(ticket.qty, 10) - parseInt(ticket.qty_returned, 10),
              type: ticket.type,
              description: ticket.type_description.trim(),
              attractionProducType: hotelCategory,
              voucher: ticket.vouchers[0],
              bookingGroup: ticket.booking[0].booking_group,
              perRoomDining: roomDining,
              perRoomTickets: roomTickets,
              supplierConfirmationNumber:
                supplierConfirmationNumber.replace('|', ' & ') || aid9,
              hotelAddress,
              price: ticket.price,
              currency: order.currency,
              destination:
                ticket.destinations && ticket.destinations.length > 0
                  ? ticket.destinations[0].title
                  : '',
              type_ticket: ticket.type_ticket,
              booking_status:
                ticket.booking[0].booking_status === 'booked'
                  ? 'Confirmed'
                  : 'Pending',
            };
            vouchersArr.push(vouchArr);
          }
        }
      }
    });
    if (vouchersArr.length > 0) {
      const vouchers = getTicketsFromVouchers(
        vouchersArr,
        voucherDownloadableCancellation,
        bookingCanceled,
      );
      return vouchers;
    }
  }
  return null;
};

const setVoucherHotelTickets = (vouchers) => {
  const hotelTickets = [];
  vouchers.forEach((voucher) => {
    if (voucher.hotelDetails.tickets) {
      const hotelTicketIds = [];
      Object.keys(voucher.hotelDetails.tickets).forEach((tickets, key) => {
        const product = voucher.hotelDetails.tickets[tickets];
        Object.keys(product).forEach((bookingGroup) => {
          const quantity = 0;
          const ticketProduct = product[bookingGroup];
          ticketProduct.forEach((products) => {
            const ticketProductKey = Object.keys(products)[0];
            const prodIdIndex = hotelTicketIds.indexOf(ticketProductKey);
            const hotelOrderTicketsIds = [];
            if (prodIdIndex !== -1) {
              const dateKey = Object.keys(products[ticketProductKey])[0];
              if (
                hotelTickets[prodIdIndex][bookingGroup][ticketProductKey][
                  dateKey
                ]
              ) {
                hotelTickets[prodIdIndex][bookingGroup][ticketProductKey][
                  dateKey
                ].qty += Object.values(products[ticketProductKey])[0].qty;

                const orderTicketsArr = Object.values(
                  products[ticketProductKey],
                )[0].orderTickets;
                const orderTicketsKey = Object.keys(orderTicketsArr[0]);

                const hotelTicketsArr = hotelTickets[prodIdIndex][bookingGroup][ticketProductKey][
                  dateKey
                ].orderTickets;
                const hotelTicketsKey = Object.keys(hotelTicketsArr)[0];

                hotelTicketsArr.forEach((hotelTicketsVal) => {
                  if (
                    hotelOrderTicketsIds.indexOf(
                      Object.keys(hotelTicketsVal)[0],
                    ) === -1
                  ) {
                    hotelOrderTicketsIds.push(Object.keys(hotelTicketsVal)[0]);
                  }
                });

                if (hotelOrderTicketsIds.indexOf(orderTicketsKey[0]) === -1) {
                  hotelTickets[prodIdIndex][bookingGroup][ticketProductKey][
                    dateKey
                  ].orderTickets.push({
                    [orderTicketsKey]: Object.values(orderTicketsArr[0])[0],
                  });
                }
              }
            } else {
              hotelTicketIds.push(Object.keys(products)[0]);
              hotelTickets.push({ [bookingGroup]: products });
            }
          });
        });
      });
    }
  });
  return hotelTickets;
};

const setHotelTickets = (vouchers) => {
  const hotelTickets = [];
  const voucherPath = [];
  vouchers.forEach((voucher) => {
    if (voucher.tickets !== 0) {
      const hotelTicketIds = [];
      Object.keys(voucher.tickets).forEach((tickets, key) => {
        const product = voucher.tickets[tickets];
        const ticketProduct = Object.values(product)[0];
        const productKey = Object.keys(product)[0];
        const ticketProductKey = Object.keys(ticketProduct)[0];
        const products = Object.values(ticketProduct)[0];
        const prodIdIndex = hotelTicketIds.indexOf(ticketProductKey);

        // vouchers
        const voucherStatus = products.voucher.status;
        const voucherId = products.voucher.voucher_id;
        const releaseDate = products.voucher.release_date;

        const cancellationUrl = '';
        if (products.voucher.download_url.length > 0) {
          products.voucher.download_url.forEach((url) => {
            if (!(url in voucherPath)) {
              voucherPath[url] = {
                label: '',
                url: appendGeoParam(url, config.brand),
              };
            }
          });
        }
        const voucherDetails = {
          statusTitle: voucherStatus,
          status: voucherStatus,
          voucherTd: voucherId,
          path: voucherPath,
          releaseDate,
        };
        if (prodIdIndex !== -1) {
          hotelTickets[ticketProductKey].orderTickets.push(
            products.orderTickets,
          );
        } else {
          hotelTicketIds.push(ticketProductKey);
          hotelTickets[ticketProductKey] = {
            img: products.img,
            orderTickets: [products.orderTickets],
            title: products.title,
            voucher: voucherDetails,
          };
        }
      });
    }
  });
  return hotelTickets;
};

export const getHotelCategory = (productCategories) => {
  const hotel = {
    isHotel: false,
    hotelCategory: '',
  };
  const productCategory = Object.values(productCategories);
  /* eslint-disable-next-line no-plusplus */
  for (let i = 0; i < productCategory.length; i++) {
    if (
      productCategory[i].indexOf('Accommodation') !== -1
      || productCategory[i].includes('Accommodation')
    ) {
      hotel.isHotel = true;
      hotel.hotelCategory = productCategory[i].toLowerCase();
      break;
    }
  }
  return hotel;
};

const checkVoucherBooking = (ticketBooking) => {
  // Check if the ticket is a voucher.
  let isVoucherBooking = false;
  ticketBooking.forEach((booking) => {
    if (booking.dispatch_method === 'evoucher') {
      isVoucherBooking = true;
    }
  });
  return isVoucherBooking;
};

const getAccommodationOccupancy = (occupantList, checkOut) => {
  let key = '';
  let match = '';
  const occupancy = [];
  occupantList.forEach((occupant) => {
    const names = occupant.split('\n');
    const type = names.shift().toLowerCase();
    if (type.indexOf('adults') !== -1) {
      key = 'adults';
    } else if (type.indexOf('children') !== -1) {
      key = 'children';
    } else if (type.indexOf('infants') !== -1) {
      key = 'infants';
    }

    if (key === 'adults') {
      match = /[A-Za-z]+/g;
    } else {
      match = /.+ \([0-9]{1,2} [A-Z-a-z]{3} [0-9]{4}\)/g; //eslint-disable-line
    }
    const detailsArr = [];
    names.forEach((name) => {
      if (name.match(match)) {
        let age = '';
        // Split by the first space.
        const splitNameArr = name.split(' ');
        const splitName = splitNameArr.splice(0, 1);
        splitName.push(splitNameArr.join(' '));
        // Get the name after the first space.
        let fullName = splitName[1].trim();

        // Get the name after the first space.// Extract the age of a child.
        if (key === 'children' || key === 'infants') {
          const regex = /\((.*?)\)/g;
          const dobString = regex.exec(fullName);

          if (dobString[1].length > 0) {
            // Remove the date of birth from the name string.
            fullName = fullName.replace(dobString[0], '');

            // Work out the age of the child based on the
            // checkout date for the stay.
            if (key === 'children') {
              // Convert the date of birth to a timestamp.
              const dobTimestamp = new Date(dobString[1]).getTime();
              // Convert dd/mm/yyyy date values to mm/dd/yyyy.
              const checkOutDate = checkOut.split('/');
              const formattedCheckOutDate = `${checkOutDate[1]}/${checkOutDate[0]}/${checkOutDate[2]}`;
              const checkOutTimestamp = new Date(
                formattedCheckOutDate,
              ).getTime();
              // Get the difference between the two dates.
              const difference = checkOutTimestamp - dobTimestamp;
              age = Math.floor(difference / (1000 * 60 * 60 * 24) / 365);
            }
          }
        }
        detailsArr.push({ name: fullName, age });
      }
    });
    occupancy.push({ [key]: detailsArr });
  });
  return occupancy;
};

export const getTicketsFromVouchers = (
  vouchersArr,
  voucherDownloadableCancellation,
  bookingCanceled,
) => {
  let vouchers = [];
  let hotelDetails = [];
  // Get the hotel checkout flow conditions as they can also dictate the
  // layout for the customer accounts.
  let roomDining = false;
  let roomTickets = false;
  const mapTickets = [];
  vouchersArr.forEach((voucher) => {
    let voucherDetails = [];
    const voucherPath = [];
    let mapDining = [];

    voucher.forEach((voucherTicket) => {
      if (
        voucherTicket.attractionProducType
        && voucherTicket.attractionProducType.toLowerCase() === 'accommodation'
      ) {
        roomDining = voucherTicket.perRoomDining;
        roomTickets = voucherTicket.perRoomTickets;
        return; /* eslint no-useless-return: 0 */
      }
    });

    voucher.forEach((voucherTicket) => {
      switch (voucherTicket.attractionProducType) {
        case 'accommodation':
          {
            // Convert dd/mm/yyyy date values to mm/dd/yyyy.
            const checkInDate = voucherTicket.checkIn.split('/');
            const formattedCheckInDate = `${checkInDate[1]}/${checkInDate[0]}/${checkInDate[2]}`;

            // Convert dd/mm/yyyy date values to mm/dd/yyyy.
            const checkOutDate = voucherTicket.checkOut.split('/');
            const formattedCheckOutDate = `${checkOutDate[1]}/${checkOutDate[0]}/${checkOutDate[2]}`;

            // Get the difference between the two dates.
            const daysDiff = new Date(formattedCheckOutDate).getTime()
              - new Date(formattedCheckInDate).getTime();
            const days = Math.round(daysDiff / (1000 * 60 * 60 * 24));

            // BAU-52 DLP Hotel displaying number of days in the tickets for
            // room only customers.
            let ticketDuration = days + 1;
            if (voucherTicket.description.includes('Room Only')) {
              ticketDuration = 0;
            } else if (
              voucherTicket.ticketId === '276457'
              || voucherTicket.ticketId === '276470'
              || voucherTicket.ticketId === '276450'
            ) {
              // If the hotel booking is for Les Villages.
              // Set the number of days to 2.
              ticketDuration = 2;
            }
            const orderTicketId = voucherTicket.orderTicketId;

            if (hotelDetails.rooms && hotelDetails.rooms.length > 0) {
              hotelDetails = {
                img: voucherTicket.img,
                title: voucherTicket.hotelTitle,
                checkin: voucherTicket.checkIn,
                checkout: voucherTicket.checkOut,
                supplierConfirmationNumber:
                  voucherTicket.supplierConfirmationNumber,
                duration: days,
                hotelAddress: voucherTicket.hotelAddress,
                ticketDaysDuration: ticketDuration,
                voucher: voucherTicket.voucher,
                rooms: hotelDetails.rooms,
                bookingStatus: voucherTicket.booking_status,
              };
              hotelDetails.rooms.push([
                {
                  orderTicketId: {
                    title: voucherTicket.ticketTitle,
                    occupancy: voucherTicket.occupancy,
                    bookingGroup: voucherTicket.bookingGroup,
                    description: voucherTicket.description,
                    img: voucherTicket.img,
                  },
                },
              ]);
            } else {
              hotelDetails = {
                img: voucherTicket.img,
                title: voucherTicket.hotelTitle,
                checkin: voucherTicket.checkIn,
                checkout: voucherTicket.checkOut,
                supplierConfirmationNumber:
                  voucherTicket.supplierConfirmationNumber,
                duration: days,
                hotelAddress: voucherTicket.hotelAddress,
                ticketDaysDuration: ticketDuration,
                voucher: voucherTicket.voucher,
                rooms: [],
                bookingStatus: voucherTicket.booking_status,
              };
              hotelDetails.rooms.push([
                {
                  orderTicketId: {
                    title: voucherTicket.ticketTitle,
                    occupancy: voucherTicket.occupancy,
                    bookingGroup: voucherTicket.bookingGroup,
                    description: voucherTicket.description,
                    img: voucherTicket.img,
                  },
                },
              ]);
            }
          }
          break;
        case 'accommodation tickets':
        case 'accommodation tickets (free sell permitted)':
          {
            // If the end date is populated, we don't need to return the ticket
            // date information. We want to take the date and time and create a
            // date time info string to display to the customer.
            let ticketDate = '';
            let dateKey = voucherTicket.dateId;
            if (
              !voucherTicket.dateTime.endDate
              && voucherTicket.dateTime.startDate
              && voucherTicket.dateTime.startDate.length > 0
            ) {
              // Convert dd/mm/yyyy date values to mm/dd/yyyy.
              const ticketStartDate = voucherTicket.dateTime.startDate.split('/');
              const formattedStartDate = `${ticketStartDate[1]}/${ticketStartDate[0]}/${ticketStartDate[2]}`;
              const ticketStartDateTimestamp = new Date(
                formattedStartDate,
              ).getTime();
              // let ticketsDate = moment(ticketStartDateTimestamp).format('Do MMM YYYY');
              let ticketsDate = voucherTicket.dateTime.startDate;
              dateKey = `${ticketStartDate[2]}${ticketStartDate[1]}${ticketStartDate[0]}`;

              // Format and add the time for the ticket.
              if (
                voucherTicket.dateTime.time
                && voucherTicket.dateTime.time.length > 0
              ) {
                const formattedTime = voucherTicket.dateTime.time.substr(0, 5);
                ticketsDate += ` ${formattedTime.replace(':', ' ')}`;
              }
              ticketDate = format(new Date(formattedStartDate), 'do MMM yyyy');
            }
            // If booking group is available, this indicates that we can group
            // items against the room.
            if (roomTickets === true && voucherTicket.bookingGroup > 0) {
              const dateKeyArr = {
                img: voucherTicket.img,
                title: voucherTicket.ticketTitle,
                qty: voucherTicket.qty,
                bookingGroup: voucherTicket.bookingGroup,
                dateInfo: ticketDate,
                orderTickets: [
                  {
                    [voucherTicket.orderTicketId]: {
                      qty: voucherTicket.qty,
                      type: voucherTicket.type,
                      description: voucherTicket.description,
                    },
                  },
                ],
                destination: voucherTicket.destination,
                typeTicket: voucherTicket.type_ticket,
              };

              if (hotelDetails.tickets) {
                const bookingGroup = voucherTicket.bookingGroup;
                if (hotelDetails.tickets.bookingGroup) {
                  hotelDetails.tickets.bookingGroup.push([
                    {
                      [voucherTicket.productId]: {
                        [dateKey]: dateKeyArr,
                      },
                    },
                  ]);
                } else {
                  hotelDetails.tickets.push({
                    [voucherTicket.bookingGroup]: [
                      {
                        [voucherTicket.productId]: {
                          [dateKey]: dateKeyArr,
                        },
                      },
                    ],
                  });
                }
              } else {
                hotelDetails.tickets = [];
                hotelDetails.tickets.push({
                  [voucherTicket.bookingGroup]: [
                    {
                      [voucherTicket.productId]: {
                        [dateKey]: dateKeyArr,
                      },
                    },
                  ],
                });
              }
              // If there is no booking group, the ticket is likely to be a non
              // hotel ticket or a ticket via a different supplier.
            } else {
              mapTickets.push({
                [voucherTicket.orderTicketId]: {
                  [dateKey]: {
                    img: voucherTicket.img,
                    title: voucherTicket.ticketTitle,
                    qty: voucherTicket.qty,
                    dateInfo: ticketDate,
                    voucher: voucherTicket.voucher,
                    orderTickets: {
                      [voucherTicket.orderTicketId]: {
                        qty: voucherTicket.qty,
                        type: voucherTicket.type,
                        description: voucherTicket.description,
                        price: voucherTicket.price,
                        date: voucherTicket.dateTime.startDate,
                        destination: voucherTicket.destination,
                        type_ticket: voucherTicket.type_ticket,
                      },
                    },
                  },
                },
              });
            }
          }
          break;
        case 'accommodation dining':
          // If booking group is available, this indicates that we can group
          // items against the room.
          if (roomDining === true && voucherTicket.bookingGroup > 0) {
            const diningArr = {
              img: voucherTicket.img,
              title: voucherTicket.ticketTitle,
              bookingGroup: voucherTicket.bookingGroup,
              orderTickets: [
                {
                  [voucherTicket.orderTicketId]: {
                    qty: voucherTicket.qty,
                    type: voucherTicket.type,
                    description: voucherTicket.description,
                  },
                },
              ],
            };

            if (hotelDetails.dining) {
              const bookingGroup = voucherTicket.bookingGroup;
              if (hotelDetails.dining.bookingGroup) {
                hotelDetails.dining.bookingGroup.push([
                  {
                    [voucherTicket.productId]: diningArr,
                  },
                ]);
              } else {
                hotelDetails.dining.push({
                  [voucherTicket.bookingGroup]: [
                    {
                      [voucherTicket.productId]: diningArr,
                    },
                  ],
                });
              }
            } else {
              hotelDetails.dining = [];
              hotelDetails.dining.push({
                [voucherTicket.bookingGroup]: [
                  {
                    [voucherTicket.productId]: diningArr,
                  },
                ],
              });
            }
            // If there is no booking group, the dining plan is likely to be a
            // non hotel plan or a plan via a different supplier.
            // This is unlikely to occur.
          } else {
            mapDining = {
              [voucherTicket.productId]: {
                img: voucherTicket.img,
                title: voucherTicket.ticketTitle,
                qty: voucherTicket.qty,
                orderTickets: {
                  [voucherTicket.orderTicketId]: {
                    qty: voucherTicket.qty,
                    type: voucherTicket.type,
                    description: voucherTicket.description,
                  },
                },
              },
            };
          }
          break;
        default:
          break;
      }

      // Process the voucher specific information against the voucher ticket.
      // We only need to run through this process once as the same information
      // is held against each ticket.
      if (Object.keys(voucherDetails).length === 0 && hotelDetails.voucher) {
        const voucherStatus = hotelDetails.voucher.status;
        const voucherId = hotelDetails.voucher.voucher_id;
        const releaseDate = hotelDetails.voucher.release_date;

        const cancellationUrl = '';
        if (hotelDetails.voucher.download_url.length > 0) {
          hotelDetails.voucher.download_url.forEach((url) => {
            if (!(url in voucherPath)) {
              voucherPath[url] = {
                label: `${hotelDetails.type} ${hotelDetails.description}`,
                url: appendGeoParam(url, config.brand),
              };
            }
          });
        }
        voucherDetails = {
          statusTitle: voucherStatus,
          status: voucherStatus,
          voucherTd: voucherId,
          path: voucherPath,
          releaseDate,
          cancellationVoucher: `${cancellationUrl}/cancellation`,
          cancellationVoucherDownloadable: voucherDownloadableCancellation,
          currency: voucherTicket.currency,
          date: voucherTicket.dateTime.startDate,
          destination: voucherTicket.destination,
          typeTicket: voucherTicket.type_ticket,
        };
      }
    });
    if (Object.keys(voucherDetails).length > 0 || mapTickets) {
      vouchers = [
        {
          hotelDetails,
          tickets: mapTickets,
          dining: mapDining,
          vouchers: voucherDetails,
          bookingCanceled,
        },
      ];
    }
  });
  const hotelTickets = setVoucherHotelTickets(vouchers);
  vouchers[0].hotelDetails.tickets = hotelTickets;
  const hotelTicketsArr = setHotelTickets(vouchers);
  vouchers[0].tickets = hotelTicketsArr;
  // As we can have cross over seasons with dining, it is better
  // to get the qty by accumulating the order tickets qtys.
  const hotelDining = [];
  vouchers.forEach((voucher) => {
    if (voucher.hotelDetails.dining && voucher.hotelDetails.dining.length > 0) {
      const hotelDiningIds = [];
      Object.keys(voucher.hotelDetails.dining).forEach((bookingGroup) => {
        const product = voucher.hotelDetails.dining[bookingGroup];
        const bookingGroupId = Object.keys(product)[0];
        Object.keys(product).forEach((productId) => {
          const diningProduct = product[productId];
          diningProduct.forEach((diningTicket) => {
            const ticketProductKey = Object.keys(diningTicket)[0];
            const prodIdIndex = hotelDiningIds.indexOf(ticketProductKey);

            const orderDiningArr = Object.values(diningTicket)[0].orderTickets;
            const orderTicketsKey = Object.keys(orderDiningArr[0]);

            const orderTickets = Object.values(diningTicket)[0].orderTickets[0];
            const quantity = Object.values(orderTickets)[0].qty;
            diningTicket[ticketProductKey].qty = quantity;

            if (prodIdIndex !== -1) {
              const hotelDiningOrderTickets = hotelDining[prodIdIndex][bookingGroupId][ticketProductKey]
                .orderTickets;
              hotelDining[prodIdIndex][bookingGroupId][
                ticketProductKey
              ].orderTickets.push(orderTickets);
              hotelDining[prodIdIndex][bookingGroupId][ticketProductKey].qty
                += quantity;
            } else {
              hotelDiningIds.push(ticketProductKey);
              hotelDining.push({ [bookingGroupId]: diningTicket });
            }
          });
        });
      });
    }
  });
  vouchers[0].hotelDetails.dining = hotelDining;
  return vouchers;
};

export const getTransportTickets = (order) => {
  if (order.order_tickets) {
    const transportTickets = {
      confirmationNumber: '',
      tickets: [],
    };
    const ticketType = {
      outbound: [],
      inbound: [],
    };

    // Loop through to create a transport array.
    const tickets = order.order_tickets.forEach((ticket) => {
      // Check if the ticket is a transport.
      let isTransport = false;
      let transportTicket = [];

      Object.values(ticket.product_categories).forEach((productCategories) => {
        if (productCategories.indexOf('Transport') !== -1) {
          isTransport = true;
        }
      });

      if (isTransport === true) {
        const supplierApiCode = ticket.booking[0].supplier_api_code.split(':');
        let changes = 0;
        if (supplierApiCode.length > 2) {
          changes = supplierApiCode.length - 2;
        }
        let departDate = '';
        let arrivalDate = '';
        let departTime = '';
        let arrivalTime = '';
        let confirmationNumber = '';
        let typeDescription = '';
        let transportType = '';

        if (ticket.attributes.length > 0) {
          ticket.attributes.forEach((attributes) => {
            const attributesArray = attributes.attribute_id.split('-');
            const attributeId = attributesArray[2];

            // Get the departure date.
            if (attributeId === '5' && attributes.values.length > 0) {
              departDate = attributes.values[0];
              // Get the arrival date.
            } else if (attributeId === '42' && attributes.values.length > 0) {
              arrivalDate = attributes.values[0];
              // Get the departure time.
            } else if (attributeId === '10' && attributes.values.length > 0) {
              departTime = attributes.values[0];
              // Get the arrival time.
            } else if (attributeId === '57' && attributes.values.length > 0) {
              arrivalTime = attributes.values[0];
              // Get the Confirmation Number.
            } else if (attributeId === '9' && attributes.values.length > 0) {
              confirmationNumber = attributes.values[0];
              transportTickets.confirmationNumber = confirmationNumber;
            }
          });
        }
        const hours = Math.floor(ticket.booking[0].duration / 60);
        const minutes = Math.floor(ticket.booking[0].duration % 60);
        const duration = `${hours}h ${minutes}m`;

        if (ticket.type_description.length === 0) {
          typeDescription = 'Standard';
        } else {
          typeDescription = ticket.type_description;
        }
        const typeArray = {
          type: ticket.type,
          qty: ticket.qty,
        };

        if (Object.values(ticket.product_categories).includes('Outbound')) {
          transportType = 'Outbound';
          ticketType.outbound.push(typeArray);
        }
        if (Object.values(ticket.product_categories).includes('Inbound')) {
          transportType = 'Inbound';
          ticketType.inbound.push(typeArray);
        }

        transportTicket = {
          type: ticket.type,
          qty: ticket.qty,
          transportType,
          productTitle: ticket.product_title,
          destinations: ticket.destinations,
          productCategories: ticket.product_categories,
          dateId: ticket.date_id,
          tags: ticket.tags,
          img: ticket.img,
          supplierApiCode: ticket.booking[0].supplier_api_code,
          changes,
          departDate,
          arrivalDate,
          departTime,
          arrivalTime,
          confirmationNumber,
          duration,
          typeDescription,
        };

        if (Object.values(ticket.product_categories).includes('Outbound')) {
          if (!transportTickets.tickets.outbound) {
            transportTickets.tickets.outbound = transportTicket;
          }
        }
        if (Object.values(ticket.product_categories).includes('Inbound')) {
          if (!transportTickets.tickets.inbound) {
            transportTickets.tickets.inbound = transportTicket;
          }
        }
      }
    });
    if (ticketType.inbound.length > 0) {
      transportTickets.tickets.inbound.ticketType = ticketType.inbound;
    }
    if (ticketType.outbound.length > 0) {
      transportTickets.tickets.outbound.ticketType = ticketType.outbound;
    }
    return transportTickets;
  }
  return null;
};

export const formatDate = (dateStr) => {
  const { t } = useTranslation('common');

  if (!dateStr) return null;

  const parseDate = (year, month, day) => {
    // Use Date.UTC to create a date in UTC, avoiding timezone issues
    const utcDate = new Date(Date.UTC(year, month - 1, day));

    const itemDay = utcDate.getUTCDate();
    const itemMonth = t(utcDate.toLocaleString('default', { month: 'short' }));
    const itemYear = utcDate.getUTCFullYear();

    return `${itemDay} ${itemMonth} ${itemYear}`;
  };

  // Handle different date input formats
  if (dateStr.includes('/')) {
    const parts = dateStr.split('/');
    return parts[0].length === 4
      ? parseDate(parseInt(parts[0], 10), parseInt(parts[1], 10), parseInt(parts[2], 10))
      : parseDate(parseInt(parts[2], 10), parseInt(parts[1], 10), parseInt(parts[0], 10));
  }

  if (dateStr.includes('-')) {
    const parts = dateStr.split('-');
    return parts[0].length === 4
      ? parseDate(parseInt(parts[0], 10), parseInt(parts[1], 10), parseInt(parts[2], 10))
      : parseDate(parseInt(parts[2], 10), parseInt(parts[1], 10), parseInt(parts[0], 10));
  }

  // Handle compact date format (e.g., 230825)
  if (dateStr.match(/^\d{6}$/)) {
    const dateFragments = dateStr.match(/.{1,2}/g);
    return parseDate(
      parseInt(`20${dateFragments[2]}`, 10),
      parseInt(dateFragments[1], 10),
      parseInt(dateFragments[0], 10),
    );
  }

  return null;
};

export const formatTime = (time) => {
  if (/^\d{4}$/.test(time)) {
    return `${time.slice(0, 2)}:${time.slice(2)}`;
  }

  if (/:/.test(time) && time.split(':').length === 3) {
    return time.split(':').slice(0, 2).join(':');
  }

  return time;
};

// Append GeoParam to url for ATD brand
export const appendGeoParam = (url, brand) => {
  const lang = getLang();
  if (isATBrand()) {
    url = url.includes('?') ? `${url}&geo=${lang}` : `${url}?geo=${lang}`;
  }
  return url;
};

// Prepend language prefix for ATD brand
export const prependLangUrlPath = (path, brand, prefix) => {
  if (isATBrand()) {
    path = `/${prefix}${path}`;
  }
  return path;
};

export const isATBrand = () => {
  if (config.site_name === 'AttractionTickets') {
    return true;
  }
  return false;
};

export const formatName = (firstName, secondName) => {
  let fullName = '';
  if (secondName) {
    fullName = `${firstName} ${secondName}`;
  } else {
    fullName = firstName;
  }

  if (fullName) {
    fullName = fullName
      .toLowerCase()
      .split(' ')
      .filter((name) => name !== '');
    for (let i = 0; i < fullName.length; i += 1) {
      fullName[i] = fullName[i][0].toUpperCase() + fullName[i].substring(1);
    }
    fullName = fullName.join(' ');
    if (fullName.includes("'")) {
      fullName = fullName.split('');
      const index = fullName.indexOf("'") + 1;
      fullName[index] = fullName[index].toUpperCase();
      fullName = fullName.join('');
    }
    if (fullName.includes('-')) {
      fullName = fullName.split('');
      const index = fullName.indexOf('-') + 1;
      fullName[index] = fullName[index].toUpperCase();
      fullName = fullName.join('');
    }
  }
  return fullName;
};

export const formatCustomerOrderStatus = (status) => {
  let orderStatus = status && status.replaceAll('_', ' ');
  if (status && status.includes('processing')) {
    orderStatus = orderStatus.split(' ')[0];
  }
  orderStatus = orderStatus && orderStatus.charAt(0).toUpperCase() + orderStatus.slice(1);
  return orderStatus;
};
