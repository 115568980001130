import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import styles from '../GiroPay/GiroPay.module.scss';
import {
  CheckoutDotComAPIPayment,
  CheckoutDotComAPISettings,
} from '../../../../api/CheckoutDotComAPI';
import * as utils from '../../../../utils/OrderUtils';
import Payments from '../../../Analytics/Payments/Payments';
import Checkboxes from '../Checkboxes/Checkboxes';
import updateOrderDetails from '../../../../api/UpdateOrderDetails';

function IdealPayment(props) {
  const { t } = useTranslation('common');

  const {
    payAmount, order, failureMessage, setFailureMessage,
  } = props;
  const cardholderName = order && `${order.billing_address_fname} ${order.billing_address_lname}`;
  const [checkoutDotComPublicKey, setCheckoutDotComPublicKey] = useState('');

  const lang = utils.getLang();

  const [confirmQuoteChecked, setConfirmQuoteChecked] = useState(false);
  const [confirmTermsChecked, setConfirmTermsChecked] = useState(order && order.agree_terms === '1');
  const [receiveOffersChecked, setReceiveOffersChecked] = useState(order && order.agree_marketing === '1');

  const handleConfirmQuoteChange = (checked) => {
    setConfirmQuoteChecked(checked);
  };

  const handleConfirmTermsChange = (checked) => {
    setConfirmTermsChecked(checked);
  };

  const handleReceiveOffersChange = (checked) => {
    setReceiveOffersChecked(checked);
  };

  useEffect(() => {
    CheckoutDotComAPISettings().then((result) => {
      setCheckoutDotComPublicKey(result.public_key);
    });
  }, [checkoutDotComPublicKey]);

  const IdealPaymentCaptureHandler = (e) => {
    updateOrderDetails(order.id, order.owner, confirmQuoteChecked, receiveOffersChecked, confirmTermsChecked)
      .catch((error) => {
        console.error('Error updating order details:', error);
      });
    e.preventDefault();
    CheckoutDotComAPIPayment(
      '',
      'ideal-hosted',
      payAmount,
      order.currency,
      order.email,
      order.id,
      order.billing_address_country,
    ).then((redirectUrls) => {
      if (redirectUrls) {
        window.location.href = redirectUrls;
        Payments(order, payAmount, 'ideal-payment');
      } else {
        setFailureMessage(t('payment.failure_msg'));
      }
    });
  };

  return (
    <div>
      <p>{t('payment.payment_form.idealtab')}</p>
      {['quote', 'in_checkout', 'payment_failed'].includes(order && order.status) && (
        <Checkboxes
          order={order}
          onConfirmQuoteChange={handleConfirmQuoteChange}
          onConfirmTermsChange={handleConfirmTermsChange}
          onReceiveOffersChange={handleReceiveOffersChange}
          confirmQuoteChecked={confirmQuoteChecked}
          confirmTermsChecked={confirmTermsChecked}
          receiveOffersChecked={receiveOffersChecked}
        />
      )}
      <button className={styles.submit} disabled={(order.status === 'quote' && !confirmQuoteChecked) || (['quote', 'in_checkout', 'payment_failed'].includes(order && order.status) && !confirmTermsChecked)} onClick={IdealPaymentCaptureHandler} type="submit">
        {t('payment.payment_form.ideal_text')}
      </button>
    </div>
  );
}

export default IdealPayment;
