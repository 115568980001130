/* eslint-disable react/jsx-boolean-value */
import parse from 'html-react-parser';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'react-tooltip';
import '../../../translations/i18n';
import * as utils from '../../../utils/OrderUtils';
import styles from './ComboTickets.module.scss';
import getTicketValidityText from '../../../api/TicketValidityTextAPI';
import Download from '../../Analytics/Download/Download';

function ComboTickets(props) {
  const {
    id,
    img,
    title,
    currency,
    price,
    tickets,
    orderStatus,
    isQuote,
    destination,
    typeTicket,
    orderId,
    productId,
  } = props;

  const { t } = useTranslation('common');

  let vouchersArray = [];
  const downladStatus = [
    'quote',
    'in_checkout',
    'canceled',
    'payment_failed',
    'customer_contacted_no_sale',
    'awaiting_paypal',
  ];

  if (tickets && !isQuote) {
    vouchersArray = tickets.map((ticket) => {
      let voucherPath;
      let voucherLabel;
      const voucherUrl = [];
      const voucherStatus = [];
      let voucherStatusTitle;
      if (
        ticket.vouchers
        && ticket.vouchers.status === 'ready_to_download'
        && !downladStatus.includes(orderStatus)
      ) {
        const today = new Date().getTime();
        const releaseDate = new Date(ticket.vouchers.release_date).getTime();
        if (ticket.vouchers.release_date.length === 0 || releaseDate <= today) {
          voucherStatusTitle = (
            <p
              key={ticket.vouchers.status_title}
              className={styles.statusTitle}
            >
              {t(ticket.vouchers.status_title)}
            </p>
          );
          voucherStatus.push(voucherStatusTitle);
          voucherPath = Object.values(ticket.vouchers.path);
          voucherPath.forEach((path) => {
            if (voucherPath.length > 1) {
              voucherLabel = (
                <span key={path.label} className={styles.statusSpan}>
                  {path.label}
                </span>
              );
            }
            const url = (
              <a
                key={t('tickets.download')}
                href={path.url}
                className={styles.statusButton}
                onClick={Download(
                  destination,
                  typeTicket,
                  orderId,
                  ticket.subtitle,
                )}
              >
                {t('tickets.download')}
              </a>
            );
            voucherUrl.push(url);
          });
          voucherStatus.push(voucherLabel);
          voucherStatus.push(voucherUrl);
        } else {
          const download = (
            <>
              <p
                key={t('tickets.download_from')}
                className={styles.statusTitle}
              >
                {t('tickets.download_from')}
                {' '}
                {utils.formatDate(ticket.vouchers.release_date)}
              </p>
              <p className={styles.statusDownload}>
                <span className={`${styles.statusButton} disabled`}>
                  {t('tickets.download')}
                </span>
              </p>
            </>
          );
          voucherStatus.push(download);
        }
      } else {
        if (ticket.vouchers.status !== 'ready_to_download') {
          voucherStatus.push(
            <p
              key={t(ticket.vouchers.status_title)}
              className={styles.statusTitle}
            >
              {t(ticket.vouchers.status_title)}
            </p>,
          );
        } else if (orderStatus === 'canceled') {
          voucherStatus.push(
            <p
              key={t('tickets.booking_cancelled')}
              className={styles.statusTitle}
            >
              {t('tickets.booking_cancelled')}
            </p>,
          );
        }
        voucherStatus.push(
          <p key={t('tickets.download')} className={styles.statusDownload}>
            <span className={`${styles.statusButton} disabled`}>
              {t('tickets.download')}
            </span>
          </p>,
        );
      }
      return voucherStatus;
    });
  }

  const ticketStartDates = isQuote
    && tickets
    && tickets[0].attributes.filter((attibute) => Object.keys(attibute)[0].includes('Date'));

  const attributes = isQuote
    && tickets
    && tickets.map((ticket) => ticket.attributes.filter(
      (attibute) => !Object.keys(attibute)[0].includes('Date'),
    ));

  const blackFridayNids = ['275879', '321273', '321270', '321271', '254384', '296574', '321261', '321262', '234084', '296586', '321267', '321268', '321628', '321626', '321629', '321627', '254503', '321618', '281016', '321623', '254504', '321624', '281017', '321625', '341595', '341601', '341606'];

  return (
    <li className={styles.ticketItem} key={id}>
      <div className={styles.image}>
        <img alt="" src={img} />
      </div>
      <div className={styles.info}>
        <h3 className={isQuote ? styles.title : styles.titleMargin}>{blackFridayNids.includes(productId) && title.includes(' - ') ? title.split(' - ')[0] : title}</h3>
        {!isQuote
          && (price && price !== 0 ? (
            <p className={styles.price}>
              {parse(utils.getCurrencyCode(currency))}
              {utils.formatPrice(price)}
            </p>
          ) : (
            <p className={styles.price}>{t('FREE')}</p>
          ))}
        {ticketStartDates.length > 0 && (
          <div className={styles.ticketStartDates}>
            {ticketStartDates.map((date) => (
              <div key={Object.keys(date)} className={styles.price}>
                <b>{Object.keys(date)}</b>
                :
                {' '}
                <span>{utils.formatDate(Object.values(date)[0])}</span>
                {' '}
                <span className={styles.tooltipTitle} data-tooltip-id="ticket-validity-text" data-tooltip-html={getTicketValidityText(Object.keys(date)[0], Object.values(date)[0], productId)} data-tooltip-place="bottom"> i </span>
                <Tooltip id="ticket-validity-text" className={styles.tooltipBody} />

              </div>
            ))}
          </div>
        )}
        {tickets
          && tickets
          && tickets.map((comboTicket, i) => (
            <div className={styles.ticketComboItem} key={comboTicket.subtitle}>
              <div className={styles.info}>
                <p className={styles.subtitle}>
                  {isQuote
                    ? `${comboTicket.quantity} x ${comboTicket.subtitle}`
                    : comboTicket.subtitle}
                </p>
                {comboTicket.date && (
                  <div className={styles.dates}>
                    Start date:
                    {' '}
                    {utils.formatDate(comboTicket.date)}
                    {' '}
                    {utils.formatTime(comboTicket.time)}
                    <span className={styles.tooltipTitle} data-tooltip-id="ticket-validity-text" data-tooltip-html={getTicketValidityText(comboTicket.subtitle, comboTicket.date, productId)} data-tooltip-place="bottom"> i </span>
                    <Tooltip id="ticket-validity-text" className={styles.tooltipBody} />
                  </div>
                )}
                {isQuote && (
                  <p className={styles.price}>
                    {parse(utils.getCurrencyCode(comboTicket.currency))}
                    {utils.formatPrice(comboTicket.price)}
                  </p>
                )}
                {isQuote
                  && attributes[i].map((attribute) => (
                    <p key={Object.keys(attribute)} className={styles.price}>
                      <b>{Object.keys(attribute)}</b>
                      :
                      {' '}
                      <em>
                        <span
                          className={
                            Object.values(attribute)[0].indexOf('Required') > -1
                              ? styles.missingAttribute
                              : ''
                          }
                        >
                          {Object.values(attribute)}
                        </span>
                      </em>
                    </p>
                  ))}
                {!isQuote
                  && comboTicket.tickets.map((ticket) => (
                    <p key={ticket} className={styles.number}>
                      {ticket}
                      {' '}
                    </p>
                  ))}
              </div>
              {!isQuote && (
                <div className={styles.status}>{vouchersArray[i]}</div>
              )}
            </div>
          ))}
      </div>
    </li>
  );
}

export default ComboTickets;
