import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import styles from './RoomDetails.module.scss';
import '../../../../translations/i18n';
import { formatName } from '../../../../utils/OrderUtils';

function RoomDetails(props) {
  const { details } = props;

  const rooms = details.rooms;
  const roomDetails = [];
  rooms.forEach((room) => {
    let adultsQty = '';
    let childrenQty = '';
    let infantsQty = '';
    room[0].orderTicketId.occupancy.qty.forEach((occupancy) => {
      if (Object.keys(occupancy)[0] === 'adults') {
        adultsQty = Object.values(occupancy);
      } else if (Object.keys(occupancy)[0] === 'children') {
        childrenQty = Object.values(occupancy);
      } else if (Object.keys(occupancy)[0] === 'infants') {
        infantsQty = Object.values(occupancy);
      }
    });
    const adultsDetails = [];
    const childrenDetails = [];
    const infantsDetails = [];
    room[0].orderTicketId.occupancy.details.forEach((occupancyDetails) => {
      if (Object.keys(occupancyDetails)[0] === 'adults') {
        Object.values(occupancyDetails)[0].forEach((adults) => {
          adultsDetails.push(formatName(adults.name));
        });
      } else if (Object.keys(occupancyDetails)[0] === 'children') {
        Object.values(occupancyDetails)[0].forEach((children) => {
          const child = `${formatName(children.name)}(${children.age})`;
          childrenDetails.push(child);
        });
      } else if (Object.keys(occupancyDetails)[0] === 'infants') {
        Object.values(occupancyDetails)[0].forEach((infants) => {
          infantsDetails.push(infants.name);
        });
      }
    });
    roomDetails.push({
      key: room[0].orderTicketId.bookingGroup,
      img: room[0].orderTicketId.img,
      title: room[0].orderTicketId.title,
      description: room[0].orderTicketId.description,
      adultsQty,
      adultsDetails,
      childrenQty,
      childrenDetails,
      infantsQty,
      infantsDetails,
    });
  });

  // Tickets
  const products = [];
  if (details.tickets) {
    details.tickets.forEach((tickets) => {
      Object.values(tickets).forEach((roomTickets) => {
        Object.values(roomTickets).forEach((roomTicket) => {
          const ticket = Object.values(roomTicket)[0];
          let ticketKey = Object.keys(roomTicket)[0];
          products.push(
            <li key={`${ticketKey}-product`} className={styles.extrasItem}>
              <em>
                {ticket.qty}
                {' '}
                &times;
                {' '}
              </em>
              <strong>
                {ticket.title}
                {' '}
                {ticket.dateInfo}
              </strong>
            </li>,
          );

          Object.values(ticket.orderTickets).forEach((orderTicket) => {
            const orderTickets = Object.values(orderTicket)[0];
            ticketKey = Object.keys(orderTicket)[0];
            let ticketDesc = '';
            if (orderTickets.type) {
              if (ticket.qty !== orderTickets.qty) {
                ticketDesc = (
                  <li
                    key={`${ticketKey}-description`}
                    className={styles.extrasItemChild}
                  >
                    <em>
                      {orderTickets.qty}
                      {' '}
                      &times;
                      {' '}
                    </em>
                    <strong>
                      {orderTickets.type}
                      {' '}
                      {orderTickets.description}
                    </strong>
                  </li>
                );
              } else {
                ticketDesc = (
                  <li
                    key={`${ticketKey}-description`}
                    className={styles.extrasItem}
                  >
                    <strong>{orderTickets.description}</strong>
                  </li>
                );
              }
              products.push(ticketDesc);
            }
          });
        });
      });
    });
  }

  // Dining
  const dining = [];
  if (details.dining) {
    Object.values(details.dining).forEach((roomDining) => {
      Object.values(roomDining).forEach((roomDine) => {
        Object.values(roomDine).forEach((roomDineTicket) => {
          dining.push(
            <li className={styles.extrasItem}>
              <em>
                {roomDineTicket.qty}
                {' '}
                &times;
                {' '}
              </em>
              <strong>{roomDineTicket.title}</strong>
            </li>,
          );
          Object.values(roomDineTicket.orderTickets).forEach((orderTicket) => {
            const orderTickets = Object.values(orderTicket)[0];
            let ticketDesc = '';
            if (orderTickets.type) {
              if (roomDine.qty !== orderTickets.qty) {
                ticketDesc = (
                  <li className={styles.extrasItemChild}>
                    <em>
                      {orderTickets.qty}
                      {' '}
                      &times;
                      {' '}
                    </em>
                    <strong>
                      {orderTickets.type}
                      {' '}
                      {orderTickets.description}
                    </strong>
                  </li>
                );
              } else {
                ticketDesc = (
                  <li className={styles.extrasItem}>
                    <strong>{orderTickets.description}</strong>
                  </li>
                );
              }
              dining.push(ticketDesc);
            }
          });
        });
      });
    });
  }

  const { t } = useTranslation('common');

  let roomExtras = 0;
  if (dining.length > 0 || products.length > 0) {
    roomExtras = 1;
  }

  return (
    <>
      <h2 className={styles.title}>{t('tickets.room_summary')}</h2>
      {roomDetails.map((room) => (
        <div className={styles.rooms} key={room.key}>
          <div className={styles.image}>
            <img alt="" src={room.img} />
          </div>
          <div className={styles.info}>
            <h3 className={styles.roomName}>{room.title}</h3>
            <h4 className={styles.ticketSubtitle}>{room.description}</h4>
            <div className={styles.details}>
              <div className={styles.detailsChunk}>
                <ul className={styles.occupants}>
                  {room.adultsQty > 0 && (
                    <li className={styles.occupantsItem}>
                      <em>
                        {room.adultsQty}
                        {' '}
                        &times;
                        {t('tickets.adults')}
                        :
                        {' '}
                      </em>
                      <strong>{room.adultsDetails.join(',  ')}</strong>
                    </li>
                  )}
                  {room.childrenQty > 0 && (
                    <li className={styles.occupantsItem}>
                      <em>
                        {room.childrenQty}
                        {' '}
                        &times;
                        {t('tickets.children')}
                        :
                        {' '}
                      </em>
                      <strong>{room.childrenDetails.join(',  ')}</strong>
                    </li>
                  )}
                  {room.infantsQty > 0 && (
                    <li className={styles.occupantsItem}>
                      <em>
                        {room.infantsQty}
                        {' '}
                        &times;
                        {t('tickets.infants')}
                        :
                        {' '}
                      </em>
                      <strong>{room.infantsDetails.join(',  ')}</strong>
                    </li>
                  )}
                </ul>
              </div>
              {roomExtras > 0 && (
                <div className={styles.detailsChunk}>
                  <ul className={styles.extras}>
                    {dining}
                    {products}
                  </ul>
                </div>
              )}
            </div>
          </div>
        </div>
      ))}
    </>
  );
}

export default RoomDetails;
