import React, { useState, useEffect, useMemo } from 'react';
import { useCookies } from 'react-cookie';
import * as utils from '../utils/OrderUtils';
import API from '../api/phx';
import { getAppConfig } from '../config/config';

const SessionContext = React.createContext({
  isLoggedIn: false,
  session: {},
  onLogin: (userId, orderId, sid) => {},
});

export function SessionContextProvider(props) {
  const { children } = props;
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [session, setSession] = useState({});
  const [cookies, setCookie] = useCookies(['customer']);
  const config = getAppConfig();

  const getSession = async () => {
    try {
      const sessionApiPath = utils.appendGeoParam(
        '/session?extras=login_order_id',
        config.brand,
      );
      let response;
      if (utils.isATBrand()) {
        response = await API.get(sessionApiPath);
      } else {
        const sessionId = window.localStorage.getItem('sid');
        if (sessionId) {
          response = await API.get(sessionApiPath, {
            headers: { Authorization: `Basic ${sessionId}` },
          });
        } else {
          response = await API.get(sessionApiPath);
        }
      }

      const sessionObject = response.data;
      if (config.brand === 'DSD' && sessionObject.user_id && sessionObject.id) {
        // Allow access only to users with view_agency_orders permission
        if (sessionObject?.trade?.view_agency_orders === 1) {
          const path = window.location.pathname.split('/');
          loginHandler(sessionObject.user_id, path[2], sessionObject.id);
          setIsLoggedIn(true);
          setCookie('user', sessionObject.user_id, { path: '/' });
          setCookie('order', path[2], { path: '/' });
        }
      } else if (
        sessionObject.user_id
        && sessionObject.login_order_id
        && sessionObject.id
      ) {
        loginHandler(
          sessionObject.user_id,
          sessionObject.login_order_id,
          sessionObject.id,
        );
        setIsLoggedIn(true);
        setCookie('user', sessionObject.user_id, { path: '/' });
        setCookie('order', sessionObject.login_order_id, { path: '/' });
      }
    } catch (err) {
      if (!err.response) {
        console.warn('No Server Response');
      } else {
        console.warn(err);
      }
    }
  };

  useEffect(() => {
    getSession();
  }, []);

  const loginHandler = (userId, orderId, sid) => {
    localStorage.setItem('sid', sid);
    localStorage.setItem('lastLoginDate', new Date().toISOString());
    setSession({ sid, loginOrder: orderId });
    setIsLoggedIn(true);
    sessionStorage.setItem('sid', sid);
    sessionStorage.setItem('orderId', orderId);
  };

  const sessionProviderValue = useMemo(
    () => ({
      isLoggedIn,
      session,
      onLogin: loginHandler,
    }),
    [isLoggedIn, session],
  );

  return (
    <SessionContext.Provider value={sessionProviderValue}>
      {children}
    </SessionContext.Provider>
  );
}

export default SessionContext;
