import { encode } from 'js-base64';
import API from './phx';
import * as utils from '../utils/OrderUtils';
import { getAppConfig } from '../config/config';

const config = getAppConfig();

export const CheckoutDotComAPIPayment = async (cardToken, paymentMethod, paymentAmount, currencyCode, email, saveCardChecked, orderId, country) => {
  const amount = utils.getAmount(paymentAmount);

  let action = 'payment';
  let storecard = '';
  if (paymentMethod === 'card') action = 'card'; // Credit card.
  if (saveCardChecked) storecard = 'Y';

  // Submit capture request:
  const checkoutUrl = encode(window.location.href);
  const uri = utils.appendGeoParam(
    `/payments/checkoutdotcom/${paymentMethod}?action=${action}&account=1&checkout_url=${checkoutUrl}&order_id=${orderId}`,
    config.brand,
  );
  const data = {
    email,
    value: amount,
    currency: currencyCode,
    trackid: orderId,
    cardToken,
    storecard,
  };

  if (country) {
    data.billing_address_country = country;
  }

  let redirectUrl = '';
  try {
    const resp = await API.post(uri, data);
    if (resp.status === 200) {
      if (resp.data._links) {
        /* eslint no-underscore-dangle: 0 */
        redirectUrl = resp.data._links.redirect.href; /* eslint no-underscore-dangle: 0 */
      } else if (resp.data.redirectUrl) {
        redirectUrl = resp.data.redirectUrl;
      } else {
        console.error(
          'CheckoutDotComAPI - capturePayment error: redirectUrl missing from response.',
          resp.data,
        ); /* eslint no-console: 0 */
      }
    } else {
      console.error(
        'CheckoutDotComAPI - capturePayment error: redirectUrl missing from response.',
        resp.data,
      );
    }
  } catch (error) {
    console.error('CheckoutDotComAPI - Failed to capture payment.', error);
    window.location.reload();
  }
  return redirectUrl;
};

export const CheckoutDotComAPISettings = async () => {
  const requestUrl = utils.appendGeoParam(
    '/payments/checkoutdotcom/settings',
    config.brand,
  );
  const resp = await API.get(requestUrl);
  const settings = resp?.data;
  return settings;
};

export const CheckoutDotComAPIPaymentWithPreviousCard = async (
  cardId,
  paymentAmount,
  currencyCode,
  email,
  orderId,
) => {
  const amount = utils.getAmount(paymentAmount);
  const action = 'cardId'; // Giropay, Sofort.

  // Submit capture request:
  const checkoutUrl = encode(window.location.href);
  const uri = utils.appendGeoParam(
    `/payments/checkoutdotcom/card?action=${action}&account=1&checkout_url=${checkoutUrl}&order_id=${orderId}`,
    config.brand,
  ); // account=1 = Customer account payment.
  const data = {
    email,
    value: amount,
    currency: currencyCode,
    trackid: orderId,
    cardId,
  };

  let redirectUrl = '';
  try {
    const result = await API.post(uri, data);
    if (result.status === 200) {
      if (result.data._links) {
        /* eslint no-underscore-dangle: 0 */
        redirectUrl = result.data._links.redirect.href; /* eslint no-underscore-dangle: 0 */
      } else {
        console.error(
          'CheckoutDotComAPI - capturePayment error: redirectUrl missing from response.',
          result.data,
        ); /* eslint no-console: 0 */
      }
    } else {
      console.error(
        'CheckoutDotComAPI - capturePayment error: redirectUrl missing from response.',
        result.data,
      );
    }
  } catch (err) {
    console.log(`err${err}`);
  }
  return redirectUrl;
};
