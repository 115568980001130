import React, { useState, useEffect, useMemo } from 'react';

const PaymentContext = React.createContext({
  orderUpdate: false,
  paymentStatus: '',
  paymentData: (data) => {},
  forceFetch: false,
});

export function PaymentContextProvider(props) {
  const { children } = props;
  const [orderUpdate, setOrderUpdate] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState({});
  const [forceFetch, setForceFetch] = useState(false);
  const [payAmount, setPayAmount] = useState();

  const paymentDataHandler = (status, amount) => {
    if (status) {
      setPaymentStatus(status);
      setOrderUpdate(true);
      setForceFetch(!forceFetch);
      if (amount.includes('.')) {
        setPayAmount(amount);
      } else {
        setPayAmount(`${amount}.00`);
      }
    }
  };

  const paymentProviderValue = useMemo(
    () => ({
      orderUpdate,
      paymentStatus,
      paymentData: paymentDataHandler,
      forceFetch,
      payAmount,
    }),
    [orderUpdate, paymentStatus, forceFetch],
  );

  return (
    <PaymentContext.Provider value={paymentProviderValue}>
      {children}
    </PaymentContext.Provider>
  );
}

export default PaymentContext;
