import { useTranslation } from 'react-i18next';
import Tickets from '../Tickets/Tickets';
import ComboTickets from '../ComboTickets/ComboTickets';
import styles from './TicketDownload.module.scss';
import '../../../translations/i18n';

function TicketDownload(props) {
  const { t } = useTranslation('common');
  const { tabID, vouchers, orderId } = props;
  // Sort products ordered by price, then quantity
  vouchers
    ?.sort((a, b) => (b.quantity < a.quantity ? -1 : 1))
    .sort((a, b) => (b.price < a.price ? -1 : 1));

  const nonComboVouchers = vouchers?.filter((voucher) => !voucher.combo);
  const comboVouchers = [];
  vouchers
    ?.filter((voucher) => voucher.combo)
    .forEach((voucher) => {
      if (comboVouchers.length === 0) {
        comboVouchers.push([voucher]);
      } else {
        comboVouchers.forEach((vouch) => {
          if (vouch[0].title === voucher.title) {
            vouch.push(voucher);
          } else {
            comboVouchers.push([voucher]);
          }
        });
      }
    });

  const nonComboOrderTickets = nonComboVouchers
    && nonComboVouchers.map((ticket) => (
      <Tickets
        key={ticket.vouchers.voucher_id}
        id={ticket.vouchers.voucher_id}
        title={ticket.title}
        img={ticket.img}
        date={ticket.date}
        time={ticket.time}
        tickets={ticket.tickets}
        price={ticket.price}
        currency={ticket.currency}
        vouchers={ticket.vouchers}
        data={ticket.data}
        orderStatus={ticket.order_status}
        bookOnDeposit={ticket.book_on_deposit}
        bookingStatus={ticket.booking_status}
        destination={ticket.destination}
        typeTicket={ticket.type_ticket}
        orderId={orderId}
        productId={ticket.product_id}
      />
    ));

  const comboOrderTickets = comboVouchers
    && comboVouchers.map((ticket) => (
      <ComboTickets
        key={ticket[0].vouchers.voucher_id}
        id={ticket[0].vouchers.voucher_id}
        title={ticket[0].title}
        img={ticket[0].img}
        tickets={ticket}
        price={ticket[0].price}
        currency={ticket[0].currency}
        orderStatus={ticket[0].order_status}
        isQuote={false}
        destination={ticket[0].destination}
        typeTicket={ticket[0].type_ticket}
        orderId={orderId}
        productId={ticket[0].product_id}
      />
    ));

  return (
    <div id={tabID} className={styles.downloadTickets}>
      <h2 className={styles.title}>{t('payment.tickets_download_tab')}</h2>
      <ul className={styles.comboTicketsList}>{comboOrderTickets}</ul>
      <ul className={styles.ticketsList}>{nonComboOrderTickets}</ul>
    </div>
  );
}

export default TicketDownload;
