import { useState, useEffect, useCallback } from 'react';
import API from './phx';
import * as utils from '../utils/OrderUtils';

const getTicketValidityText = (ticketTitle, date, productId) => {
  const year = date.includes('/') ? date.split('/')[2] : date.split('-')[2];

  const [ticketValidityText, setTicketValidityText] = useState('');

  let fetchUrl = '';

  const noEpic2025ProductIds = ['328366', '226957', '328398', '328524', '328539', '328580', '328613'];

  const epic2025ProductIds = ['333439', '333450', '336195', '338242', '338244', '338254'];

  if (ticketTitle && ticketTitle.includes('Disney')) {
    fetchUrl = utils.appendGeoParam(
      '/text?types=ticket_validity_tooltip_disney',
    );
  } else if (ticketTitle.includes('SeaWorld')) {
    fetchUrl = utils.appendGeoParam(
      '/text?types=ticket_validity_tooltip_seaworld',
    );
  } else if (ticketTitle.includes('Universal')) {
    if (year === '2024') {
      fetchUrl = utils.appendGeoParam(`/text?types=ticket_validity_tooltip_universal_${year}`);
    } else if (noEpic2025ProductIds.includes(productId)) {
      fetchUrl = utils.appendGeoParam(`/text?types=ticket_validity_tooltip_universal_${year}`);
    } else if (epic2025ProductIds.includes(productId)) {
      fetchUrl = utils.appendGeoParam(`/text?types=ticket_validity_tooltip_universal_${year}_with_epic`);
    } else {
      fetchUrl = utils.appendGeoParam('/text?types=ticket_validity_tooltip_universal');
    }
  }

  const fetchData = useCallback(async () => {
    const resp = await API.get(fetchUrl);
    const data = await resp?.data;
    if (ticketTitle && ticketTitle.includes('SeaWorld')) {
      let seaworldTicketValidity = data.data[0].body.split('December');
      seaworldTicketValidity = `${seaworldTicketValidity[0]}December ${year}.${seaworldTicketValidity[1]}`;
      setTicketValidityText(seaworldTicketValidity);
    } else {
      setTicketValidityText(data.data[0].body);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return ticketValidityText;
};

export default getTicketValidityText;
