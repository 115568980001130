/* eslint-disable no-underscore-dangle */
import axios from 'axios';
import * as utils from '../../../../utils/OrderUtils';
import { loading } from '../../../../utils/ExternalPaymentsUtils';
import Payments from '../../../Analytics/Payments/Payments';

export default function googlePay(order, paymentRequest, amountToPay, countryCode, setRedirectUrl, paymentData) {
  const domainConfig = utils.getLang();
  const BACKEND_URL_PAY = `https://${window.location.hostname}/api/payments/checkoutdotcom/google-pay?action=googlepay&cached=0&account=1&geo=${domainConfig}`;

  order.amountToPay = (amountToPay * 100).toFixed();
  order.countryCode = countryCode;
  order.order_id = order.id;
  const details = { orderData: order };

  const parsedToken = JSON.parse(paymentRequest.paymentMethodData.tokenizationData.token);

  details.token = { paymentData: parsedToken };

  axios.post(
    BACKEND_URL_PAY,
    {
      details,
    },
    {
      headers: { 'Access-Control-Allow-Origin': '*' },
    },
  ).then(({ data }) => {
    if (data && ('_links' in data) && ('3ds' in data) && ('redirect' in data._links)) {
      setRedirectUrl(data._links.redirect.href);
    } else if (data && data.approved && !('3ds' in data) && (data.status === 'Authorized') && (data.responseMessage === 'Approved')) {
      if (!data.risk.flagged) {
        loading();
        const amountLeftToPay = String(Number(order.balance) - Number(amountToPay));
        paymentData('payment-success', amountLeftToPay);
        Payments(order, amountToPay, 'google-pay');
        loading(false);
      } else if (data.risk.flagged) {
        paymentData('payment-pending', String(Number(order.balance)));
        loading(false);
      }
    } else if (data && !data.approved && (data.status === 'Declined')) {
      paymentData('payment-failed', amountToPay);
      loading(false);
    }
  });
}
