import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { getAppConfig, getSiteConfig } from '../../../config/config';
import styles from './InfoBlock.module.scss';
import * as utils from '../../../utils/OrderUtils';

function InfoBlock() {
  const { t } = useTranslation('common');
  const config = getAppConfig();

  const [showPaymentInfo, setShowPaymentInfo] = useState(false);

  useEffect(() => {
    const lang = utils.getLang();
    const siteConfig = getSiteConfig();
    const langs = siteConfig.depositEnabledLangs;
    if (langs.includes(lang)) {
      setShowPaymentInfo(true);
    }
  }, []);

  return (
    utils.isATBrand() ? (
      <>
        <h2 className={styles.customerAccountsSubtitle}>
          {t('login.info_box.title')}
        </h2>
        <p className={styles.customerAccountsIntro}>
          {t('login.info_box.info_p')}
        </p>
        <ul className={styles.list}>
          {showPaymentInfo && <li>{t('login.info_box.info_list1')}</li>}
          <li>{t('login.info_box.info_list2')}</li>
          <li>{t('login.info_box.info_list3')}</li>
        </ul>
        <p className={styles.linkBtn}>
          <a href="/">{t('login.info_box.link_text')}</a>
        </p>
        <h2 className={styles.customerAccountsSubtitle}>
          {t('login.info_box.get_in_touch')}
        </h2>
        <p className={styles.customerAccountsIntro}>
          {t('login.info_box.contact_text')}
        </p>
        <p className={styles.telephone}>
          <a href={`tel:${config.phone_number}`}>{config.phone_number}</a>
        </p>
        <p className={styles.opening}>{t('login.info_box.opening_hours')}</p>
      </>
    ) : (
      <>
        <h2 className={styles.customerAccountsSubtitle}>
          Contact Us
        </h2>
        <p className={styles.customerAccountsIntro}>
          If you would like to get in touch to book or discuss any of our tickets, please don&apos;t hesitate to contact us.
          {' '}
          <b>For the fastest response to your questions, it is recommended that you call rather than email where possible.</b>
        </p>
        <h2 className={styles.customerAccountsSubtitle}>
          Phone
        </h2>
        <p className={styles.customerAccountsIntro}>
          Please call our Sales Team on 0800 221 8546 (or 0044 203 1950 657 if calling from overseas).
        </p>
        <h2 className={styles.customerAccountsSubtitle}>
          Email
        </h2>
        <p className={styles.customerAccountsIntro}>
          For help with existing bookings, email
          {' '}
          <a href="mailto:customerservices@americanattractions.co.uk" target="_blank" rel="noreferrer">customerservices@americanattractions.co.uk</a>
          ; For new booking enquiries and group rates, please contact us at:
          {' '}
          <a href="mailto:sales@americanattractions.co.uk" target="_blank" rel="noreferrer">sales@americanattractions.co.uk</a>
          .
        </p>
      </>
    )
  );
}

export default InfoBlock;
